import styled from "styled-components";

export const LoadingLayerWrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(255,255,255,0.5);
    z-index: 99998;
    display:${({show})=> (show === true ? 'block' : 'none')};
`;

export const LoadingIndicator = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid rgba(149,31,118,1);
    border-left-color: rgb(209,78,58);
    border-radius: 974px;
    -o-border-radius: 974px;
    -ms-border-radius: 974px;
    -webkit-border-radius: 974px;
    -moz-border-radius: 974px;
    margin: -24px 0 0 -24px;
    height: 49px;
    width: 49px;
    animation: cssload-rotate 1150ms linear infinite;
      -o-animation: cssload-rotate 1150ms linear infinite;
      -ms-animation: cssload-rotate 1150ms linear infinite;
      -webkit-animation: cssload-rotate 1150ms linear infinite;
      -moz-animation: cssload-rotate 1150ms linear infinite;


    &:before{
      position: absolute;
      top: 50%;
      left: 50%;
      border: 1px solid rgba(149,31,118,1);
      border-left-color: rgb(209,78,58);
      border-radius: 974px;
      -o-border-radius: 974px;
      -ms-border-radius: 974px;
      -webkit-border-radius: 974px;
      -moz-border-radius: 974px;
      content: "";
      margin: -22px 0 0 -22px;
      height: 43px;
      width: 43px;
      animation: cssload-rotate 1150ms linear infinite;
      -o-animation: cssload-rotate 1150ms linear infinite;
      -ms-animation: cssload-rotate 1150ms linear infinite;
      -webkit-animation: cssload-rotate 1150ms linear infinite;
      -moz-animation: cssload-rotate 1150ms linear infinite;

    }

    &:after{
      position: absolute;
      top: 50%;
      left: 50%;
      border: 1px solid rgba(149,31,118,1);
      border-left-color: rgb(209,78,58);
      border-radius: 974px;
      -o-border-radius: 974px;
      -ms-border-radius: 974px;
      -webkit-border-radius: 974px;
      -moz-border-radius: 974px;
      content: "";
      margin: -28px 0 0 -28px;
      height: 55px;
      width: 55px;
      animation: cssload-rotate 2300ms linear infinite;
        -o-animation: cssload-rotate 2300ms linear infinite;
        -ms-animation: cssload-rotate 2300ms linear infinite;
        -webkit-animation: cssload-rotate 2300ms linear infinite;
        -moz-animation: cssload-rotate 2300ms linear infinite;

    }

  
  
`;