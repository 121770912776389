import styled from 'styled-components';

export const InfoContainer = styled.div`
  position:relative;
  color:#696969;
  background: #fff;

  @media screen and (max-width:990px){
    /* padding:0px 0; */
  }
`;

export const InfoWrapper = styled.div`
  display:grid;
  z-index:1;
  width:100%;
  max-width:1400px;
  margin-right:auto;
  margin-left:auto;
  padding:0 24px;
  justify-content:center;
  padding-top:120px;
  padding-bottom:120px;

  @media screen and (max-width: 990px){
    max-width:800px;
    padding-left:40px;
    padding-right:40px;
    padding-bottom:60px;
  }

  @media screen and (max-width: 375px){
    padding-left:20px;
    padding-right:20px;
  }  


`;

export const InfoRow = styled.div`
  display:grid;
  width:100%;
  grid-auto-columns: minmax(auto, 1fr);
  align-items:center;
  grid-template-areas: 'col2 col1';

  @media screen and (max-width: 990px){
    grid-template-areas: 'col1' 'col2'
  }

`;

export const Column1 = styled.div`
  /* margin-bottom:15px; */
  padding:0 15px;
  grid-area: col1;

  @media screen and (max-width: 990px){
    padding-left:0px;
    padding-right:0px;
  }

`;
export const Column2 = styled.div`
  /* margin-bottom:15px; */
  padding:0 15px;
  grid-area: col2;
  
  @media screen and (max-width: 990px){
    padding-left:0px;
    padding-right:0px;
  }  
`;

export const TextWrapper = styled.div`
  max-width:100%;
  padding-top:0;
  /* padding-bottom:60px; */
`;

export const TopLine = styled.p`
  color:#951f76;
  font-size:16px;
  line-height:16px;
  font-weight:700;
  letter-spacing:1.4px;
  text-transform:uppercase;
  margin-bottom:16px;
`;

export const Heading = styled.h1`
  color:#d14e3a;
  margin-bottom:24px;
  font-size:48px;
  line-height:1.1;
  font-weight:600;
  color: ${({lightText})=>(lightText ? '#f7f8fa' : '#d14e3a')};

  @media screen and (max-width: 990px){
    font-size:2rem;
  }
`;

export const Subtitle = styled.p`
  max-width:700px;
  margin-bottom:35px;
  font-size:18px;
  line-height:24px;
  color:#696969;
  text-align:justify;
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content:flex-start;
`;

export const ImgWrap = styled.div`
  max-width:700px;
  display: flex;
  justify-content:center;
  height:100%;
`;

export const Img = styled.img`
  width:400px;
  margin:0 0 10px 0;
  padding-right:0px;

  @media screen and (max-width: 990px){
    width:100%;
    max-width:400px;
    margin-top:30px;
  }


`;

