import styled from 'styled-components';
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md';


export const HeroContainer = styled.div`
  position:relative;
  display:flex;
  justify-content:center;
  align-items:center;
  padding:0 30px;
  height:calc(100vh - 50px);
  position:relative;
  z-index:1;
  :before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
  }

  @media screen and (min-width:991px) and (max-height:620px){
    margin-bottom:150px;
  }   

  @media screen and (max-width:1280px) and (max-height:750px){
    margin-bottom:100px;
  }   


  @media screen and (max-width: 990px){
    height:100vh;
    margin-top:200px;
    margin-bottom:100px;
    align-items:unset;
  }  

  @media screen and (max-width: 680px){
    margin-top:150px;
    height:860px;
    margin-bottom:60px;
    display:block;
    padding:0 10px;
  }  

  @media screen and (max-width: 375px){
    margin-top:150px;
    height:860px;
    margin-bottom:60px;
    display:block;
    padding:0 10px;
  }

`;

export const HeroBg = styled.div`
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  width:100%;
  height:100%;
  overflow:hidden;
`;

export const VideoBg = styled.video`
  width: 100%;
  height:100%;
  -o-object-fit:cover;
  object-fit:cover;
  opacity:5%;
`;

export const HeroContent = styled.div`
  z-index:3;
  max-width:1400px;
  position:absolute;
  
  padding:8px 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-template-areas: 'col1 col2';
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items:center;

  @media screen and (min-width: 1280px) and (max-width:1500px){
    padding:8px 40px;
  }  


  @media screen and (min-width: 991px) and (max-width:1279px){
    padding:8px 40px;
  }  

  

  @media screen and (max-width: 990px){
    display:flex;
    flex-direction:column;
    max-width:800px;
  }  

  @media screen and (max-width: 375px){
    padding:8px 10px;
  }  


`;

export const HeroLeft = styled.div`

/* background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%); */
  grid-area: col1;
  display: flex;
  flex-direction: column;
  align-items: baseline;

  @media screen and (max-width: 990px){
    align-items:center;
    //padding:0px;
  }

`;

export const HeroRight = styled.div`
  grid-area: col2;
  justify-content:center;
  display: flex;
`;

export const BannerIMG = styled.img`
  width: 100%;
  max-height: 500px;
  height: auto;  

  @media screen and (max-width: 990px){
    margin-top:100px;
    max-width:500px;
  }  

  @media screen and (max-width: 768px){  
  }

`;

export const HeroH1 = styled.h1`
  color:#951f76;
  font-size:44px;
  font-weight:bold;
  text-align:left;

  @media screen and (max-width: 990px){
    font-size:40px;
  }

  @media screen and (max-width:480px){
    font-size:32px;
  }
`;

export const HeroP = styled.p`
  margin-top:24px;
  color:#8574a5;
  font-size:24px;
  text-align:left;
  max-width:600px;
  line-height:40px;

  @media screen and (max-width: 990px){
    font-size:24px;
    max-width:100%;
  }

  @media screen and (max-width:480px){
    font-size:18px;
  }  
`;

export const HeroBtnWrapper = styled.div`
  margin-top:32px;
  display:flex;
  flex-direction:column;
  align-items: center;

 

`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left:8px;
  font-size:20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left:8px;
  font-size:20px;
`;

export const ScrollDown = styled.div`
    width: 30px; 
    height: 50px;
    border: 2px solid #8574a5;
    border-radius: 25px; 
    position: absolute;
    left:0;
    right:0;
    margin:auto;
    bottom:-150px;

    @media screen and (max-width:990px){
      display:none;
    }
    @media screen and (min-width:991px) and (max-height:730px){
      bottom:-150px;
    }
    @media screen and (min-width:991px) and (max-height:600px){
      bottom:-80px;
    }



    @media screen and (max-width:1255px){
      bottom:-100px;
    }

    @media screen and (max-width:1255px){
      bottom:-100px;
    }    

    @media screen and (max-width:1280px) and (max-height:900px){
      bottom:-100px;
    }       

    @media screen and (min-width:1280px) and (max-height:790px){
      bottom:-70px;
    }          
    
    @media screen and (min-width:1390px) and (max-height:790px){
      bottom:-100px;
    }              

  :before{
    content: ""; 
    position: absolute;
    width: 8px; 
    height: 8px;
    background: #8574a5; 
    margin: auto;
    left: 0; 
    right: 0;
    top: 10px; 
    border-radius: 50%;
    animation: animate 1s linear infinite; 
  }

  @keyframes animate {
    0% {
        opacity: 1; top: 10px;
    }
    100% {
        opacity: 0; top: 40px;
        transform: scale(0.8);
    }
}

`;