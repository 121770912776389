import React, {useState} from 'react'
import SideBar from '../components/sidebar/sidebar.component';
import NavBar from '../components/navbar/navbar.component';
import HeroSection from '../components/herosection/herosection.component';
import { homeObjAbout, homeObjTeam, homeObjApproach,homeObjWhatWeOffer,homeObjContact} from '../components/infosection/data';
//import Platform from '../components/platform/platform.component';
import About from '../components/about/about.component';
import Approach from '../components/approach/approach.component';
import WhatWeOffer from '../components/whatweoffer/whatweoffer.component';
import Footer from '../components/footer/footer.component';
import MidLayer from '../components/midlayer/midlayer.component';
import Team from '../components/team/team.component';
import Contact from '../components/contact/contact';
const Home = ({reactga}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <SideBar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} reactga={reactga} />
      <HeroSection />
      <MidLayer />
      <About {...homeObjAbout} />
      <Team {...homeObjTeam} reactga={reactga} />
      <Approach {...homeObjApproach} />
      <WhatWeOffer {...homeObjWhatWeOffer} />
      {
        /*
        <Platform {...homeObjPlatform} />
        */
      }
      <Contact {...homeObjContact} />
      <Footer/>
      
    </>
  )
}

export default Home