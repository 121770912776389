export const homeObjApproach = {
  id: 'approach',
  lightBg:true,
  lightText:false,
  lightTextDesc:true,
  topLine: 'Nodify Tech',
  headline: 'Unlimited Transactions with zero fees',
  description: 'Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees.',
  buttonLabel: 'Get Started',
  imgStart:false,
  img: require('../../assets/images/svg-1.svg').default,
  alt: 'Car',
  dark: true,
  primary:true,
  darkText:false
}

export const homeObjAbout = {
  id: 'about',
  lightBg:true,
  lightText:false,
  lightTextDesc:false,
  topLine: 'About',
  headline: 'Who we are',
  description: 'Nodify is the provider of components in the form of ideas, services and products for wiser workflows and document life cycle utilization.',
  description2:'We are incorporated at the beginning of 2009. Nevertheless, our origins can be traced back to 1998 when our initial document processing engine was developed by the members of our software team.',
  buttonLabel: 'Learn More',
  imgStart:true,
  img: require('../../assets/images/svg-2.svg').default,
  alt: 'Nodify',
  dark: false,
  primary:false,
  darkText:true
}

export const homeObjPlatform = {
  id: 'theplatform',
  lightBg:true,
  lightText:false,
  lightTextDesc:false,
  topLine: 'The Platform',
  headline: 'Creating a workflow with integration is extremely easy',
  description: "Praesent massa erat, commodo dignissim gravida quis, volutpat eget urna. Proin varius, purus eu pulvinar finibus, mi sapien faucibus ex, eu pretium est justo sit amet leo.",
  buttonLabel: 'Contact Us',
  imgStart:false,
  img: require('../../assets/images/efficiency-simplicity.png'),
  alt: 'Platform',
  dark: false,
  primary:false,
  darkText:true
}

export const homeObjContact = {
  id: 'contact',
  lightBg:true,
  lightText:false,
  lightTextDesc:true,
  imgStart:false,
  img: require('../../assets/images/svg-3.svg').default,
  alt: 'Contact',
  dark: false,
  primary:false,
  darkText:true
}

export const homeObjTeam = {
  id: 'team',
  lightBg:true,
  lightText:false,
  lightTextDesc:true,
  imgStart:false,
  img: require('../../assets/images/svg-3.svg').default,
  alt: 'team',
  dark: false,
  primary:false,
  darkText:true
}

export const homeObjWhatWeOffer = {
  id: 'whatweoffer',
  lightBg:true,
  lightText:false,
  lightTextDesc:true,
  imgStart:false,
  img: require('../../assets/images/svg-3.svg').default,
  alt: 'what we offer',
  dark: false,
  primary:false,
  darkText:true
}