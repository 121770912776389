import styled from "styled-components";

export const MaskImg = styled.img`
  opacity:0;
  transition: all 0.5s ease-in-out;
`;

export const MaskWrapperImg = styled.div`
  position:absolute;
  display:flex;
  align-items:center;
  justify-content:center;

  height:180px;
  /* height: 180px; */
  width: 180px;
  border-radius: 50%!important;  
  transition: all 0.5s ease-in-out;

  &:hover{
    background: rgba(178,130,161,0.4);
    border-radius: 50%!important;  
    cursor: pointer;
  }

  &:hover ${MaskImg}{
    transition: all 0.5s ease-in-out;
    opacity:1;
  }


  @media screen and (min-width: 991px) and (max-width:1279px){
    height:120px;
  /* height: 180px; */
    width: 120px;    
  }


`;

