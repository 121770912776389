import React,{ useEffect, useState} from 'react';
import  { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import {animateScroll as scroll} from 'react-scroll';

import {
  Nav,
  NavBarContainer,
  NavLogo,
  LogoIMG,
  MobileIcon,
  NavMenu,
  NavItem,
  NavBtnLinkSub,
  NavButtonWrapper
} from './navbarsub.style';



const NavBarSub = ({toggle,reactga}) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [hover,setHover] = useState(false);


  const changeNav = () => {
    if (window.scrollY >= 120){
      setScrollNav(true);
    }else{
      setScrollNav(false);
    }
  }

  useEffect(()=>{
    window.addEventListener('scroll',changeNav)
  },[])

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  const onHover = () => {
    setHover(!hover);
  }


  return (
    <>
    <IconContext.Provider value={{color:'#951f76'}}>
      <Nav scrollNav={scrollNav}>
        <NavBarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            <LogoIMG scrollNav={scrollNav} src='/assets/images/nodify-logo.png' alt="Nodify Tech" />
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu scrollNav={scrollNav}>
            <NavItem>
              <NavButtonWrapper>
                  <NavBtnLinkSub
                    to="/"
                    onMouseEnter={onHover}
                    onMouseLeave={onHover}
                    smooth={true}
                    duration={500}
                    spy={true}
                    primary={false}
                    dark={false}>
                    back to home
                  </NavBtnLinkSub>   
              </NavButtonWrapper>         
            </NavItem>                                
          </NavMenu>
        </NavBarContainer>
      </Nav>    
    </IconContext.Provider>
    </>
  )
}

export default NavBarSub;