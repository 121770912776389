import React from 'react'
import { MaskImg,MaskWrapperImg } from './teamimgmask.style';

const TeamImgMask = () => {

  return (
    <>
      <MaskWrapperImg>
        <MaskImg src={require('../../assets/images/team-detail-icon.png')} alt="" />
      </MaskWrapperImg>
    </>
  )
}

export default TeamImgMask;