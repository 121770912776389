import React, {useState} from 'react'
import NavBarSub from '../components/navbarsub/navbarsub.component';
//import Platform from '../components/platform/platform.component';
import FooterSub from '../components/footersub/footersub.component';
import ContactPopup from '../components/contactpopup/contactpopup.component';
import SideBarPopup from '../components/sidebarpopup/sidebarpopup.component';
const ContactPage = ({reactga}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <SideBarPopup isOpen={isOpen} toggle={toggle} />
      <NavBarSub toggle={toggle} reactga={reactga} />
      <ContactPopup/>
      <FooterSub/>
      
    </>
  )
}

export default ContactPage;