import React from 'react'
import {
  SideBarContainer,
  Icon,
  CloseIcon,
  SideBarMenu,
  SideBarRoute
} from './sidebarpopup.style.js';

function SideBarPopup({isOpen,toggle}) {
  return (
    <SideBarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SideBarMenu>
        <SideBarRoute to="/">back to home</SideBarRoute>
      </SideBarMenu>
    </SideBarContainer>
  )
}

export default SideBarPopup;