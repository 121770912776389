import styled from "styled-components";

export const DetailTeamPopup = styled.div`
  position:relative;
  padding:30px;
  background:rgba(1,1,1,0.3);
  position:fixed;
  width:100%;
  height:100%;
  display:${({visible})=>(visible === true ? 'flex' : 'none')};
  transition: all 0.5s ease-in-out;
  top:0;
  left:0;
  z-index:10;

  @media screen and (max-width: 990px){
    padding:10px;
  }

  @media screen and (max-width: 440px){
    padding:10px;
  }
 

`;


export const TeamDetailWrapper = styled.div`
  display:flex;
  position:relative;
  width:60%;
  height:550px;
  background:rgba(255,255,255,0.9);
  margin:auto;  
  transition: all 1s ease-in-out;
  border-radius:5px;
  box-shadow: 0 0 60px rgb(178 130 161 / 35%) !important;

  @media screen and (min-width: 1921px){
    width:50%;
    height:50%;
    min-height:600px;
  }


  @media screen and (min-width: 1501px) and (max-width:1920px){
    width:80%;
    height:80%;
    min-height:600px;
  }

  @media screen and (min-width: 1280px) and (max-width:1500px){
    width:80%;
    height:80%;
    min-height:600px;
  }

  @media screen and (min-width: 991px) and (max-width:1279px){
    width:99%;
    height:99%;
  }

  @media screen and (max-width: 990px){
    width:100%;
    height:100%;
    justify-content:center;
  }

  @media screen and (max-width: 440px){
    width:100%;
    height:100%;
    justify-content:center;
  }
 


`;


// export const CloseButton = styled(Link)`
//   position:absolute;
//   right:50px;
//   top:50px;
// `;

export const CloseButton = styled.button`
  background:none;
  border:none;
  width:30px;
  height:30px;
  position:absolute;
  right:20px;
  top:20px;

  &:hover{
    cursor:pointer;
  }
`;

export const DetailGridWrapper = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  padding:40px;
  padding-top:80px;
  padding-bottom:15px;

`;

export const DetailGrid = styled.div`
  display: grid;
  width:100%;
  grid-auto-columns: minmax(auto, auto);
  align-items:start;
  grid-template-areas: 'col1 col2';  
  overflow:auto;

  @media screen and (max-width: 990px){
    grid-template-areas: 'col1' 'col2';
    padding:20px;
    padding-top:60px;
  }
`;

export const DetailLeft = styled.div`
  margin-bottom:15px;
  padding:0 15px;
  grid-area: col1;
  max-width:500px;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;

  @media screen and (max-width: 990px){
    justify-content:center;
    align-items:center;
    margin-left:auto;
    margin-right:auto;
  }  

`;
export const DetailRight = styled.div`
  position:relative;
  margin-bottom:15px;
  padding:0 15px;
  grid-area: col2;
  width:100%;
  display:flex;
  flex-wrap:wrap;
  min-width:100%;
  justify-content:center;
  height: calc(100% - 10px); 

  @media screen and (max-width: 990px){
    height:calc(50vh - 100px);
    overflow-y:auto;
  }

  @media screen and (max-width: 680px){
    height: calc(50vh - 150px);
    overflow-y:auto;
    
  }  

  // @media screen and (min-width: 991px){
  //   overflow: auto;
  //   height: calc(100% - 10px);    
  // }



`;

export const DetailImg = styled.img`
  max-width:300px;
  height:auto;
  border-radius:50%;

  @media screen and (max-width: 990px){
    max-width:240px;
  }

  @media screen and (max-width: 680px){
    max-width:160px;
  }  

  @media screen and (max-width: 375px){
    max-width:130px;
  }  

    
`;

export const DetailName = styled.h3`
  margin-top:20px;
  font-size:24px;
`;

export const DetailTitle = styled.h5`
  max-width:300px;
  font-size:17px;
  margin-top:5px;
  margin-bottom:20px;
  font-weight:500;

  @media screen and (max-width: 990px){
    text-align:center;
  }

`;

export const Linkedin = styled.a`
  color:#696969;
  font-size:21px;

  &:hover{
    color:#951f76;
  }
`;


export const ShortBio = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: #696969;  
  padding-left:15px;
  padding-right:15px;

  @media screen and (min-width: 991px) and (max-width:1279px){
    font-size:16px;
  }  

`;


// export const Linkedin = styled(Link)`
//   color:#fff;
//   text-decoration:none;
//   margin-bottom:0.5rem;
//   font-size:14px;

//   &:hover{
//     color:#951f76;
//     transition:0.3s ease-in-out;
//   }
// `;
