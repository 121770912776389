import React,{useState} from 'react';
import { 
  WarningLayerWrapper,
  WarningLayerInfo,
  WarnImg,
  WarnInfo,
  CloseButton,
  CloseImg
 } from './warninglayer.styles';

const WarningLayer = ({type,message,show,stateChanger}) => {
  return (
    <>
    <WarningLayerWrapper show={show}>
      <WarningLayerInfo>
        <WarnImg src={type=== 0 ? require('../../assets/images/done.png'): require('../../assets/images/warning.png')} />
        <WarnInfo>{message}</WarnInfo>
      </WarningLayerInfo>
      <CloseButton onClick={()=>stateChanger(type,message,false)}>
        <CloseImg src={require('../../assets/images/warning-close.png')} />
      </CloseButton>
    </WarningLayerWrapper>
    </>
  )
}

export default WarningLayer;