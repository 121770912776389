import React from 'react';
import { isIOS } from 'react-device-detect';

import {
   MidLayerContainer,
   MidLayerVideoBg,
   MidLayerBg,
   MidLayerCenter,
   MidLayerH1,
   MidLayerLeft,
   MidLayerRight,
   MidLayerSubText,
   VideoBg
  
  } from './midlayer.style';

const MidLayer = () => {
  return (
    <>
    <MidLayerContainer>
      <MidLayerVideoBg>
        <MidLayerBg isios={isIOS}>
        {
          /*
          <MidLayerLeft></MidLayerLeft>
          <MidLayerRight></MidLayerRight>
          */
        }
        <MidLayerCenter>
          <MidLayerH1>100% performing workflows with low code</MidLayerH1>
          <MidLayerSubText>Easy to implement, Low code automation, Flexible, Scalable</MidLayerSubText>
        </MidLayerCenter>
        </MidLayerBg>    
        {
          isIOS === false && <VideoBg autoPlay loop muted src='/assets/videos/video1.mp4' type='video/mp4' />
        }  

      </MidLayerVideoBg>
    </MidLayerContainer>
    </>
  )
}

export default MidLayer;