import styled from "styled-components";
import {Link} from 'react-router-dom';
import { FaCentercode } from "react-icons/fa";

export const FooterContainer = styled.footer`
  /* background-color:#851b69; */
  background-color:#951f76;
  position:fixed ;
  width:100%;
  bottom:0px;

  @media screen and (max-width:990px){
    position:fixed ;
    margin-top:70px;
  }  
  

`;

export const FooterWrap = styled.div`
  padding:18px 24px;
  /* padding-bottom:5px; */
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  max-width:1230px;
  margin:0 auto;

  @media screen and (max-width: 990px){
    max-width:100%;
    flex-direction:column;
    padding-bottom:30px;
  }  

`;

export const FooterLinksContainer = styled.div`
  display:flex;
  justify-content: center;

  @media screen and (max-width:990px){
    padding-top:32px;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width:990px){
    flex-direction:column;
  }
`;

export const FooterLinkItems = styled.div`
  display:flex;
  flex-direction:column;
  align-items: flex-start;
  margin:16px;
  text-align:left;
  width:160px;
  box-sizing:border-box;
  color:#fff;

  @media screen and (max-width:420px){
    margin:0;
    padding:10px;
    width:100%;
  }
`;

export const FooterLinkTitle = styled.h1`
  font-size:14px;
  margin-bottom:16px;
`;

export const FooterLink = styled(Link)`
  color:#fff;
  text-decoration:none;
  margin-bottom:0.5rem;
  font-size:14px;

  &:hover{
    color:#951f76;
    transition:0.3s ease-in-out;
  }
`;
  
export const SocialMedia = styled.section`
  max-width:100%;
  width:100%;
`;

export const SocialMediaWrap = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  max-width:1230px;
  padding-left:15px;
  padding-right:15px;
  margin:0px auto 0 auto;

  @media screen and (max-width:990px){
    flex-direction:column;
  }
`;


export const SocialLogo = styled(Link)`
  color:#fff;
  justify-self:start;
  cursor:pointer;
  text-decoration:none;
  font-size:1.5rem;
  display:flex;
  align-items:center;
  position: relative;
  max-height:30px;
  padding-left:15px;
  margin-bottom:5px;

  img{
    max-height:30px;
    position: relative;
  }



  @media screen and (max-width:990px){
    
    padding-left:0px;

    img{
      position:relative;
      margin-top:30px;
    }

  }  


  

`;

export const WebsiteRights = styled.small`
  color:#fff;
  /* margin-bottom:16px; */
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content:space-between;
  align-items:center;
  width:240px;
`;

export const SocialIconLink = styled.a`
  color:#fff;
  font-size:24px;
  
`;
export const TermsPrivacy = styled(Link)`
  color:#fff;
  justify-self:start;
  cursor:pointer;
  text-decoration:none;
  font-size:12px;
  display:flex;
  align-items:center;
  position: relative;
  max-height:30px;
  padding-left:15px;
  margin-bottom:5px;
`;