import React,{ useEffect, useState} from 'react'
import TeamDetail from '../teamdetail/teamdetail.component';
import TeamImgMask from '../teamimgmask/teamimgmask.component';
//import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa';
import { 
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  TeamWrapper,
  TeamItem,
  TeamImg,
  TeamTitle,
  TeamPosition
 } from './team.style';
 import { alper,duygu,berat,clark,ertug,serdar } from './teamdata';

const Team = ({lightText,darkText,id,reactga}) => {
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [detailTeam, setDetailTeam] = useState(null);

  const viewDetail = (people) => {
    setVisiblePopup(true);
    setDetailTeam(people);
    reactga.pageview(people.name);
  };

  useEffect(() => {
    if (visiblePopup === true){
      document.body.classList.add('overflowHidden');
    }else{
      document.body.classList.remove('overflowHidden');
    }

  }, [visiblePopup]);

  return (
    <>
      <InfoContainer id={id}>
        <InfoWrapper>
          <InfoRow>
            <Column1>
              <TextWrapper>
                <TopLine>Our founding team :</TopLine>
                <Heading lightText={lightText}>Customer satisfaction experts</Heading>
                <Subtitle darkText={darkText}>The entire team are experts, with their technical and management experience in deploying simple and complex solutions, combining people, processes and data.<br/><br/>Click on a team member to get more information.</Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <TeamWrapper>
                <TeamItem onClick={()=>viewDetail(alper)} >
                  <TeamImg src='/assets/images/alper-tor.jpg' alt="Alper TOR" />
                  <TeamImgMask />
                  <TeamTitle>Alper TOR</TeamTitle>
                  <TeamPosition>Managing Partner, Head of Development</TeamPosition>
                </TeamItem>
                <TeamItem onClick={()=>viewDetail(duygu)} >
                  <TeamImg src='/assets/images/duygu-mizrak.jpg' alt="Ayse Duygu MIZRAK" />
                  <TeamImgMask people="duygu" />
                  <TeamTitle>Ayse Duygu MIZRAK</TeamTitle>
                  <TeamPosition>Head of Analysis and Implementation</TeamPosition>
                </TeamItem>
                <TeamItem onClick={()=>viewDetail(berat)} >
                  <TeamImg src='assets/images/berat-tor.jpg' alt="Berat TOR" />
                  <TeamImgMask people="berat" />
                  <TeamTitle>Berat TOR</TeamTitle>
                  <TeamPosition>Development Leader</TeamPosition>
                </TeamItem>
                <TeamItem onClick={()=>viewDetail(clark)} >
                  <TeamImg src='assets/images/clark-coetzee.jpg' alt="Clark Coetzee" />
                  <TeamImgMask people="clark" />
                  <TeamTitle>Clark Coetzee</TeamTitle>
                  <TeamPosition>Channel Partner Executive</TeamPosition>
                </TeamItem>                
                <TeamItem onClick={()=>viewDetail(ertug)} >
                  <TeamImg src='../../assets/images/ertug-er.jpg' alt="Ertug ER" />
                  <TeamImgMask people="ertug" />
                  <TeamTitle>Ertug ER</TeamTitle>
                  <TeamPosition>Managing Partner</TeamPosition>
                </TeamItem>
                <TeamItem onClick={()=>viewDetail(serdar)} >
                  <TeamImg src='/assets/images/serdar-inan.jpg' alt="Serdar INAN" />
                  <TeamImgMask />
                  <TeamTitle>Serdar INAN</TeamTitle>
                  <TeamPosition>Head of Sales/Marketing</TeamPosition>
                  
                </TeamItem>
                <TeamItem>
                  
                  <TeamTitle></TeamTitle>
                  <TeamPosition></TeamPosition>
                  
                </TeamItem>              
                <TeamDetail people={detailTeam} stateChanger={setVisiblePopup} visible={visiblePopup}/>                           
              </TeamWrapper>       
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Team;