import styled from "styled-components";

export const MidLayerContainer = styled.div`
  position:relative;
  display:flex;
  /* display:none; */
  height:400px;
  width:100%;
`;

export const MidLayerVideoBg = styled.div`
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  width:100%;
  height:100%;
  overflow:hidden;

`;

export const VideoBg = styled.video`
  width: 100%;
  height:100%;
  -o-object-fit:cover;
  object-fit:cover;
  opacity:100%;
  z-index:1;
  
  /* background:#232a34; */
`;

export const MidLayerCenter = styled.div`
  position:relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding:40px;

`;

export const MidLayerH1 = styled.h2`
  font-size:2.5rem;
  color:#fff;
  text-align:center;
`;

export const MidLayerSubText = styled.p`
  text-align:center;
  margin:0;
  padding-top:10px;
  text-align:center;
  color:#ece5f6;
`

export const MidLayerBg = styled.div`
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width: 100%;
  height: 100%;
  background:${({isios})=>(isios === false ? 'rgba(149,31,118,0.8)' : 'rgba(149,31,118,1)')};
background:${({isios})=>(isios === false ? '-webkit-linear-gradient(top,rgba(149,31,118,0.8), rgba(207,78,60,0.8))' : '-webkit-linear-gradient(top,rgba(149,31,118,1), rgba(207,78,60,1))')}; ;
background:${({isios})=>(isios === false ? '-moz-linear-gradient(top, rgba(149,31,118,0.8), rgba(207,78,60,0.8))' : '-moz-linear-gradient(top, rgba(149,31,118,1), rgba(207,78,60,1))')};
background:${({isios})=>(isios === false ? 'linear-gradient(to bottom, rgba(149,31,118,0.8), rgba(207,78,60,0.8))' : 'linear-gradient(to bottom, rgba(149,31,118,1), rgba(207,78,60,1))')};
z-index:2;






`;

export const MidLayerLeft = styled.div`

`;

export const MidLayerRight = styled.div`

`;