import styled from "styled-components";

export const WarningLayerWrapper  = styled.div`
  position: fixed;
  visibility: ${({show})=>(show === true ? 'visible': 'hidden')};
  opacity:${({show})=>(show === true ? '100': '0')};
  display: block;
  width: 100%;
  bottom: 0px;
  left:0px;
  height: 92px;
  z-index: 99999999999999 !important;
  background-color:rgba(209,78,58,0.9);  
  transition:all 0.5s ease-in-out;


`;

export const WarningLayerInfo = styled.div`
    position: relative;
    display:flex;
    justify-content:center;
    align-items:center;
    height: 92px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    line-height: 92px;  
`;

export const WarnImg = styled.img`
    position: relative;
    margin-right: 20px;
    margin-left: 40px;
    width: 40px;

    @media screen and (max-width: 990px){
      margin-left:0px;
    }

    @media screen and (max-width: 375px){
      margin-left:0px;
    }    

`;

export const WarnInfo = styled.p`
    position: relative;
    font-size: 1.2rem;
    color: #FFFFFF;

  @media screen and (max-width: 990px){
    font-size:1rem;
  }

  @media screen and (max-width: 375px){
    font-size:0.8rem;
  }    



`;

export const CloseButton = styled.button`
  border:none;
  background:none;

  &:hover{
    cursor:pointer;
  }
  
`;

export const CloseImg = styled.img`
    position: absolute;
    top: 20px;
    right: 20px; 
    max-height:20px; 
`;
