import styled from 'styled-components';

export const InfoContainer = styled.div`
  position:relative;
  color:#696969;
  background: #f9f9f9;

  @media screen and (max-width:990px){
    padding:40px 0;
  }
`;

export const InfoWrapper = styled.div`
  display:grid;
  z-index:1;
  /* height:860px; */
  /* height:760px; */
  width:100%;
  max-width:1400px;
  margin-right:auto;
  margin-left:auto;
  padding:0 24px;
  padding-top:120px;
  padding-bottom:120px;  
  justify-content:center;

  @media screen and (max-width:990px){
    padding:40px;
    padding-top:40px;
    padding-bottom:40px;      
  }

  @media screen and (max-width: 680px){
    padding:20px;
    padding-bottom:40px;
    padding-top:40px;
  }  

  @media screen and (max-width: 375px){
    padding:10px;
    padding-bottom:40px;
    padding-top:40px;
  }    

`;

export const InfoRow = styled.div`
  display:grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items:center;
  grid-template-areas: 'col1 col2';
  width: 100%;
  /* margin-bottom:50px; */

  @media screen and (max-width: 990px){
    grid-template-areas: 'col2' 'col1';
  }

`;

export const ExtraRow = styled.div`
  display:grid;
  align-items:center;
  width: 100%;
  margin-bottom:50px;

  @media screen and (max-width: 990px){
    margin-bottom:10px;
  }

`;

export const Column1 = styled.div`
  margin-bottom:15px;
  padding:0 15px;
  grid-area: col1;
`;
export const Column2 = styled.div`
  margin-bottom:15px;
  padding:0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  padding-top:0;
  /* padding-bottom:60px; */
`;

export const TopLine = styled.p`
  color:#951f76;
  font-size:16px;
  line-height:16px;
  font-weight:700;
  letter-spacing:1.4px;
  text-transform:uppercase;
  margin-bottom:16px;
`;

export const Heading = styled.h1`
  color:#d14e3a;
  margin-bottom:24px;
  font-size:48px;
  line-height:1.1;
  font-weight:600;
  color: ${({lightText})=>(lightText ? '#f7f8fa' : '#d14e3a')};

  @media screen and (max-width: 990px){
    font-size:32px;
    max-width:600px;
  }

  @media screen and (max-width: 480px){
    font-size:32px;
  }
`;

export const Subtitle = styled.p`
  max-width:700px;
  margin-bottom:35px;
  font-size:18px;
  line-height:24px;
  color: #696969;
  text-align:justify;

  @media screen and (max-width: 990px){
    font-size:16px;
    margin-bottom:5px;
  }

`;

export const SubtitleExtra = styled.p`
  max-width:100%;
  margin-bottom:35px;
  font-size:18px;
  line-height:24px;
  color: #696969;
  padding-left:15px;
  padding-right:15px;

  @media screen and (max-width: 990px){
    font-size:16px;
  }  

`;


export const BtnWrap = styled.div`
  display: flex;
  justify-content:flex-start;
`;

export const ImgWrap = styled.div`
  display: flex;
  justify-content:end;
  max-width:100%;
  height:100%;

  @media screen and (max-width: 990px){
    justify-content:center;
  }    


`;

export const Img = styled.img`
  width:100%;
  max-width:550px;
  margin:0 0 10px 0;
  padding-right:0px;
  margin-bottom:40px;
`;

export const ApproachDetails = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  width: 100%;
  height:100%;
`;

export const ApproachDetailTitle = styled.h3`
  color:#951f76;
  margin-bottom:40px;
  font-size:1.5rem;

  @media screen and (max-width: 990px){
    font-size:18px;
    padding:15px;
    margin-bottom:10px;
  }    

`;

export const ApproachSolutionWrapper = styled.div`
  display:grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items:center;
  grid-template-areas: 'col1 col2';
  width: 100%;

  @media screen and (max-width: 990px){
    display:unset;
  }

`;

export const SolutionColumn1 = styled.div`
  margin-bottom:15px;
  padding:0 15px;
  grid-area: col1;  
  @media screen and (max-width: 990px){
    margin-bottom:0px;
  }

`;

export const SolutionColumn2 = styled.div`
  margin-bottom:15px;
  padding:0 15px;
  grid-area: col2;  

  @media screen and (max-width: 990px){
    margin-bottom:0px;
  }  
`;

export const SolutionItemWrapper = styled.ul`
  list-style:none;
`;

export const SolutionItem = styled.li`
    display:flex;
    align-items:center;
    min-height:40px;
    font-size:1.2rem;
    line-height:24px;
    padding-left:30px;
    :before{
      content:"";
      width:15px;
      height:15px;
      border-radius:50%;
      background:#951f76;
      margin-left:-30px;
      position:absolute;
    }

    @media screen and (max-width: 990px){

      font-size:16px;

      :before{
        content:"";
        width:10px;
        height:10px;
        border-radius:50%;
        background:#951f76;
        margin-left:-30px;
        position:absolute;
      }      

    }
    


`;