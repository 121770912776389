import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';


export const Nav = styled.nav`
  background: ${({scrollNav})=> (scrollNav ? 'rgba(255,255,255,0.99)' : 'transparent')};
  height: ${({scrollNav})=> (scrollNav ? '80px' : '140px')};
  /* height: '140px'; */
  box-shadow: ${({scrollNav})=> (scrollNav ? '0 5px 15px rgb(116 116 116 / 5%)' : 'unset')};
  margin-top: -140px;
  display:flex;
  justify-content: center;
  align-items:center;
  font-size:1rem;
  position:sticky;
  top:0;
  z-index:10;
  transition: all 0.5s ease;  


  @media screen and (min-width:991px) and (max-height:730px){
    transition: none;
    margin-top:20px;
    height:80px;    
  }
  @media screen and (min-width:991px) and (max-height:500px){
    transition: none;
    margin-top:20px;
    margin-bottom:60px;
    height:80px;        
  }  

  @media screen and (max-width:990px){
    transition: 0.8s all ease;
    /* margin-top:0px; */
    height:80px;

  }
`;


export const NavBarContainer = styled.div`
  display:flex;
  justify-content: space-between;
  z-index:1;
  padding: 15px 30px;
  width:100%;
  max-width:1800px;
`;

export const NavLogo = styled(LinkR)`
  color:#fff;
  justify-self: flex-start;
  cursor:pointer;
  font-size:1.5rem;
  display:flex;
  align-items: center;
  font-weight: bold;
  margin-left:24px;
  text-decoration:none;

  @media screen and (max-width: 440px){
    margin-left:0px;
  }

`;

export const LogoIMG = styled.img`
  transition: all 0.5s ease;  
  
  -webkit-filter: drop-shadow(2px 2px 2px #ccc);
  filter: drop-shadow(5px 5px 5px #ccc);
  max-height:${({scrollNav})=> (scrollNav ? '40px' : 'unset')};
  
  @media screen and (max-width:990px){
    max-height:40px;
  }

`;

export const MobileIcon = styled.div`
  display:none;

  @media screen and (max-width: 990px){
    display: block;
    position:absolute;
    top:0;
    right:0;
    transform: translate(-100%, 60%);
    font-size:1.8rem;
    cursor:pointer;
    color:#951f76;
  }
`;


export const NavMenu = styled.ul`
  display:flex;
  align-items:center;
  list-style:none;
  text-align:center;
  margin-right:30px;

  @media screen and (max-width:990px){
    display:none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
  display: flex;
  /* &:last-child{
    align-items:center;
    margin-left:10px;
  } */
  
`;

export const NavLinks = styled(LinkS)`
  color:#696969;
  //color:${({scrollNav}) => (scrollNav === true ? '#696969' : '#fff')};
  display:flex;
  align-items:center;
  text-decoration:none;
  padding:0 1rem;
  height:100%;
  cursor:pointer;
  box-sizing:content-box;

  &.active {
    border-bottom:3px solid #951f76;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    /* box-sizing:content; */
  }

  &:hover{
    color:#951f76;
    border-bottom:3px solid #951f76;
    /* box-sizing:content;
    color:red;
    border-bottom:3px solid #951f76;
    transition: all 0.2s ease-in;
    color:#951f76; */
  }



`;


export const NavBtn = styled.nav`
  display:flex;
  align-items:center;

  @media screen and (max-width:990px){
    display:none;
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius:50px;
  background:#951f76;
  white-space:nowrap;
  padding:10px 22px;
  color:#fff;
  font-size:16px;
  outline:none;
  border:none;
  cursor:pointer;
  transition: all 0.2s ease-in-out;
  text-decoration:none;

  &:hover{
    transition: all 0.2s ease-in-out;
    background:#d14e3a;
    color:#fff;
  }
`;

export const NavBtnLinkSub = styled(LinkR)`
  border-radius:50px;
  background:#d14e3a ;
  white-space:nowrap;
  padding:10px 22px;
  color:#fff;
  font-size:16px;
  outline:none;
  border:none;
  cursor:pointer;
  transition: all 0.2s ease-in-out;
  text-decoration:none;

  &:hover{
    transition: all 0.2s ease-in-out;
    background:#951f76;
    color:#fff;
  }
`;

export const NavButtonWrapper = styled.div`

  display: flex;
  align-items:center;
  margin-left:20px;

  button{
    max-height:40px;
  }

`;


