import React from 'react'
import { 
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrap,
  Img
 } from './about.style';

const About = ({lightBg,id,imgStart,lightText,darkText}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>About</TopLine>
                <Heading lightText={lightText}>Who we are</Heading>
                <Subtitle darkText={darkText}>Nodify BPM is a flexible platform for the easy automation of business processes and information, utilizing the required document workflow lifecycle.</Subtitle>
                <Subtitle darkText={darkText}>Nodify BPM is created by a team of people, coming from three different  companies, who have extensive experience in deploying BPM and document management projects.</Subtitle>
               
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src='/assets/images/about-company.png' alt="About Nodify Tech"/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default About;