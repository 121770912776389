import React,{useState} from 'react'
import {Button} from '../button/button.style';
import {
  HeroContainer,
  HeroBg,
  HeroContent,
  HeroLeft,
  HeroRight,
  BannerIMG,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  ScrollDown
} from './herosection.style';

const HeroSection = () => {
  const [hover,setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  }


  return (
    <HeroContainer>
      <HeroBg />
      <HeroContent>
        <HeroLeft>
          <HeroH1>Build flows, automate processes; deliver rapidly!</HeroH1>
          <HeroP>Empowering your organization with the tools that enables efficient and consistent collaboration, at multiple levels, while implementing flows and automated processes; both inhouse or outsource.</HeroP>
          <HeroBtnWrapper>
            <Button
               to="whatweoffer"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              smooth={true}
              duration={500}
              spy={true}
              primary={true}
              dark={false}>
              Get Started {hover ? <ArrowForward /> : <ArrowRight/>}
            </Button>
          </HeroBtnWrapper>          
          <ScrollDown />
        </HeroLeft>
        <HeroRight>
          <BannerIMG src='/assets/images/rightbg.png' alt="Nodify Tech"/>
        </HeroRight>
      </HeroContent>
    </HeroContainer>



    
  )
}

export default HeroSection;