import React from 'react';
import { animateScroll as scroll } from 'react-scroll';


import {
   FooterContainer,
   FooterWrap,
   SocialMedia,
   SocialMediaWrap,
   SocialLogo,
   WebsiteRights,
   TermsPrivacy
} from './footer.style';

const Footer = () => {

  const toggleHome = () => {
    scroll.scrollToTop();
  }



  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <WebsiteRights> © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
            <TermsPrivacy to="/privacy-policy">Terms & Privacy</TermsPrivacy>
          </SocialMediaWrap>
        </SocialMedia>
        <SocialLogo to='/' onClick={toggleHome} >
        <img src='/assets/images/nodify-bottom-white.png' alt="Nodify Tech" />
      </SocialLogo>              
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer;