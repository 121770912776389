import styled from 'styled-components';

export const InfoContainer = styled.div`
  position:relative;
  color:#696969;
  padding-bottom:300px;
  

  @media screen and (max-width:990px){
    padding:180px 0 180px 0;
  }
`;

export const InfoWrapper = styled.div`
  display:grid;
  z-index:1;
  /* height:860px; */
  /* height:760px; */
  width:100%;
  max-width:1230px;
  margin-right:auto;
  margin-left:auto;
  padding:0 24px;
  justify-content:center;
  height: calc(100vh - 71px) ;

  @media screen and (max-width: 990px){
    padding:40px;
    padding-top:0px;
    padding-bottom:0px;
  }

  @media screen and (max-width: 375px){
    padding:20px;
    padding-top:0px;
    padding-bottom:0px;
  }    



`;

export const InfoRow = styled.div`
  display:grid;
  width:100%;
  grid-auto-columns: minmax(auto, 1fr);
  align-items:center;
  grid-template-areas: 'col1 col2';

  @media screen and (max-width: 990px){
    grid-template-areas: 'col1' 'col2'
  }

`;

export const Column1 = styled.div`
  /* margin-bottom:15px; */
  padding:0 15px;
  grid-area: col1;
`;
export const Column2 = styled.div`
  /* margin-bottom:15px; */
  padding:0 15px;
  grid-area: col2;
  @media screen and (max-width:990px){
    padding-bottom:200px;
  }  

`;

export const TextWrapper = styled.div`
  max-width:100%;
  padding-top:0;
  /* padding-bottom:60px; */
`;

export const TopLine = styled.p`
  color:#951f76;
  font-size:16px;
  line-height:16px;
  font-weight:700;
  letter-spacing:1.4px;
  text-transform:uppercase;
  margin-bottom:16px;
`;

export const Heading = styled.h1`
  color:#d14e3a;
  margin-bottom:24px;
  font-size:48px;
  line-height:1.1;
  font-weight:600;
  width:440px;
  

  @media screen and (max-width: 990px){
    width:100%;
    font-size:2rem;
  }

  @media screen and (max-width: 480px){
    font-size:32px;
  }



`;

export const Subtitle = styled.p`
  max-width:700px;
  margin-bottom:35px;
  font-size:18px;
  line-height:24px;
  color:#696969;
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content:center;
  margin-top:20px;
`;

export const ImgWrap = styled.div`
  max-width:700px;
  display: flex;
  justify-content:center;
  height:100%;
`;

export const Img = styled.img`
  width:400px;
  margin:0 0 10px 0;
  padding-right:0px;
`;


export const SocialIcons = styled.div`
  display: flex;
  align-items:center;
  width:240px;
`;

export const SocialIconLink = styled.a`
  color:#fff;
  font-size:24px;
  margin-right:15px;
`;

export const AddressWrapper = styled.div`
  display:flex;
  flex-direction:column;
  color:#fff;
  margin-top:30px;
`;

export const HeadQuarter = styled.div`
  padding:10px 0 10px 0;
`;

export const UKOffice = styled.div`
  padding:10px 0 10px 0;
`;

export const TechnicalCenter = styled.div`
  padding:10px 0 10px 0;
`;

export const Title = styled.h4`
  margin-bottom:5px;
  margin-top:10px;
`;

export const AddressTitle = styled.h5`
  margin-bottom:3px;
`;

export const AddressDetail = styled.address`
  font-size:14px;
  color:#fff;
  /* max-width:250px; */
`;
