import React from 'react'
import { 
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrap,
  Img,
  ApproachDetails,
  ApproachDetailTitle,
  ApproachSolutionWrapper,
  SolutionColumn1,
  SolutionColumn2,
  SolutionItemWrapper,
  SolutionItem,
  ExtraRow,
  SubtitleExtra
  
 } from './approach.style';

const Approach = ({lightBg,id,imgStart,topLine,lightText,headline,darkText,description,buttonLabel,img,alt,primary,dark,dark2}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>OUR APPROACH TO PROCESS MANAGEMENT</TopLine>
                <Heading lightText={lightText}>Empowering your business with the right tools</Heading>
                <Subtitle darkText={darkText}>The major trend in businesses today, is to increase customer service, driving down cost and increasing margins through the transformation, automation, and digitisation of manual administrative business processes.</Subtitle>
                <Subtitle darkText={darkText}>The absolute value to business is in empowering staff with the necessary tools that enables collaborative efficiently, consistently,  easy and flexible process mapping, business rules definition that ensures governance and control. BPM is a smart and advanced system for creating automated workflows within an organization and monitoring them to see how  processes can be improved to increase efficiency and control.</Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src='/assets/images/mind-map.svg' alt="Nodify Tech Approach"/>
              </ImgWrap>
            </Column2>
          </InfoRow>
          <ExtraRow>
            <SubtitleExtra darkText={darkText}>If your business were a machine, this would be the grease that keeps it running.</SubtitleExtra>
          </ExtraRow>
          <ApproachDetails>
            <ApproachDetailTitle>Nodify's flexible, solution offerings are based on;</ApproachDetailTitle>
            <ApproachSolutionWrapper>
              <SolutionColumn1>
                <SolutionItemWrapper>
                  <SolutionItem>Best practise process designs</SolutionItem>
                  <SolutionItem>Increased efficiency through process automation</SolutionItem>
                  <SolutionItem>Sound data and content security embedded</SolutionItem>
                  <SolutionItem>Ease of use and enhanced user experience</SolutionItem>
                </SolutionItemWrapper>
              </SolutionColumn1>
              <SolutionColumn2>
                <SolutionItemWrapper>
                  <SolutionItem>Reduction in the need for paper records and files therein saving expensive floor space</SolutionItem>
                  <SolutionItem>Document Management and control</SolutionItem>
                  <SolutionItem>Effective search and retrieval of data, content and documents</SolutionItem>
                  <SolutionItem>Integration with financial and other line of business applications</SolutionItem>
                </SolutionItemWrapper>
              </SolutionColumn2>
            </ApproachSolutionWrapper>
          </ApproachDetails>

        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Approach;