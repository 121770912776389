import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home.page.jsx';
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";
import BecomeAPartner from './pages/becomeapartner.page';
import Contact from './pages/contact.page';
import Privacy from './pages/privacy.page';
const TRACKING_ID = "UA-41187404-22"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
function App() {
  //<Router basename='/test/'>
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Nodify Technologies</title>
        <meta name="description" content="Build flows, automate processes; deliver rapidly! Nodify is a platform for the easy automation of flexible business processes and information, utilizing the required document workflow lifecycle." />
        <meta name="keywords" content="workflow,low code no code platforms,low code,usiness process management softwares,low code no code gartner,business process management best practices,Easy to implement, Flexible, Scalable, Low code-No code workflow management platform,bpm,request for quote flow,purchase request flow, supplier invoice approval flow, customer onboarding, supplier onboarding,expense claims,travel claim, travel schedule flow, international travel flow" />
        <link rel="canonical" href="https://nodify.tech/" />
      </Helmet>          
    <Router> 
      <Routes>
        <Route exact path="/" element={<Home reactga={ReactGA}/>} />
        <Route path="/become-a-partner" element={<BecomeAPartner reactga={ReactGA}/>} />
        <Route path="/become-reseller" element={<BecomeAPartner reactga={ReactGA}/>} />
        <Route path="/contact" element={<Contact reactga={ReactGA}/>} />
        <Route path="/privacy-policy" element={<Privacy reactga={ReactGA}/>} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
