import React, { useState } from 'react'
import axios from 'axios';
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  TextContent,
  ListWrapper,
  Title
} from './privacypolicy.style.js';
import WarningLayer from '../warninglayer/warninglayer.component.jsx';
import LoadingLayer from '../loadinglayer/loadinglayer.component.jsx';


const ContactPopup = ({ lightBg, id, imgStart, lightText, darkText }) => {
  const [formValue, setFormValue] = useState({
    name: '',
    title: '',
    company: '',
    email: '',
    message: '',
    showWarningMessage: '',
    showWarningType: 1
  });

  const [show, setShow] = useState(false);
  const [warning, setWarning] = useState(false);
  const [timerid, setTimerid] = useState(null);

  const showWarning = (type, message, show) => {
    try {
      clearTimeout(timerid);
    } catch (ex) { }

    setFormValue(
      {
        ...formValue,
        showWarningMessage: message,
        showWarningType: type
      }
    );
    setWarning(show);
    const timer = () => setTimeout(() => {
      setWarning(false);
    }, 3000);
    setTimerid(timer());

  }




  const handleSend = async (event) => {
    setShow(true);
    const fmData = new FormData();
    let warnmessage = "";
    if (formValue.name.trim() === "" || formValue.name === null || formValue.name === undefined){
      warnmessage = "Please check the name field.";
      showWarning(1,warnmessage,true);
      setShow(false);
      return;
    }else{
      fmData.append("name", formValue.name);
    }

    if (formValue.title.trim() === "" || formValue.title === null || formValue.title === undefined){
      warnmessage = "Please check the title field.";
      showWarning(1,warnmessage,true);
      setShow(false);
      return;
    }else{
      fmData.append("title", formValue.title);
    }

    if (formValue.company.trim() === "" || formValue.company === null || formValue.company === undefined){
      warnmessage = "Please check the company field.";
      showWarning(1,warnmessage,true);
      setShow(false);
      return;
    }else{
      fmData.append("company", formValue.company);
    }

    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(formValue.email)) {
      warnmessage = "Please check the email address.";
      showWarning(1,warnmessage,true);
      setShow(false);
      return;
    }else{
      fmData.append("email", formValue.email);
    }

    if (formValue.message.trim() === "" || formValue.message === null || formValue.message === undefined){
      warnmessage = "Please check the message field.";
      showWarning(1,warnmessage,true);
      setShow(false);
      return;
    }else{
      fmData.append("message", formValue.message);
    }

    const config = {
      headers: { "content-type": "multipart/form-data" },
      crossDomain: true
    }

    const res = await axios.post(
      "send-contact-form",
      fmData,
      config
    );
    if (res.data.type === "done"){
      warnmessage = "Contact form successfully sent!";
      showWarning(0,warnmessage,true);      
      setFormValue({
        ...formValue,
        name:'',
        title:'',
        company:'',
        email:'',
        message:'',
        showWarningMessage:warnmessage,
        showWarningType:0        
      })
      setShow(false);
    }else{
      warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";
      showWarning(1,warnmessage,true);      
      setShow(false);
    }    

  }

  const handleChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value
    });
  }


  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>Terms of Use & Privacy</TopLine>
                <Heading lightText={lightText}>Overview</Heading>
                <Subtitle darkText={darkText}>The following are terms of a legal agreement between you and Nodify. By accessing, browsing, or using this Web site, you acknowledge that you have read, understood, and agree to be bound by these terms and to comply with all applicable laws and regulations, including export and re-export control laws and regulations. If you do not agree to these terms, please do not use this Web site. Nodify may, without notice to you, at any time revise these Terms of Use and any other information contained in this Web site by updating this posting. Nodify may also make improvements or changes in the products, services, or programs described in this site at any time without notice.</Subtitle>              
                
                <Title>General</Title>
                <TextContent>
                This Web site contains proprietary notices and copyright information, the terms of which must be observed and followed. Please see the tab entitled "Copyright and trademark information" for related information.
                </TextContent>
                <TextContent>
                    This site and all content in this site may not be copied, reproduced, republished, uploaded, posted, transmitted, distributed, or used for the creation of derivative works without Nodify's prior written consent, except that Nodify grants you non-exclusive, non-transferable, limited permission to access and display the Web pages within this site, solely on your computer and for your personal, non-commercial use of this Web site. This permission is conditioned on your not modifying the content displayed on this site, your keeping intact all copyright, trademark, and other proprietary notices, and your acceptance of any terms, conditions, and notices accompanying the content or otherwise set forth in this site. Notwithstanding the foregoing, any software and other materials that are made available for downloading, access, or other use from this site with their own license terms, conditions, and notices will be governed by such terms, conditions, and notices.                  
                </TextContent>
                <TextContent>
                  Your failure to comply with the terms, conditions, and notices on this site will result in automatic termination of any rights granted to you, without prior notice, and you must immediately destroy all copies of downloaded materials in your possession or control. Except for the limited permission in the preceding paragraph, Nodify does not grant you any express or implied rights or licenses under any patents, trademarks, copyrights, or other proprietary or intellectual property rights. You may not mirror any of the content from this site on another Web site or in any other media.
                </TextContent>

                <Title>Disclaimers</Title>
                <TextContent>
                  Information on this Web site is not promised or guaranteed to be correct, current, or complete, and this site may contain technical inaccuracies or typographical errors. Nodify assumes no responsibility (and expressly disclaims responsibility) for updating this site to keep information current or to ensure the accuracy or completeness of any posted information. Accordingly, you should confirm the accuracy and completeness of all posted information before making any decision related to any services, products, or other matters described in this site.
                </TextContent>
                <TextContent>
                  Nodify provides no assurances that any reported problems will be resolved by Nodify, even if Nodify elects to provide information with the goal of addressing a problem.                    
                </TextContent>

                <Title>Confidential Information</Title>
                <TextContent>
                  Nodify does not want to receive confidential or proprietary information from you through our Web site. Please note that any information or material sent to Nodify will be deemed NOT to be confidential. By sending Nodify any information or material, you grant Nodify an unrestricted, irrevocable license to copy, reproduce, publish, upload, post, transmit, distribute, publicly display, perform, modify, create derivative works from, and otherwise freely use, those materials or information. You also agree that Nodify is free to use any ideas, concepts, know-how, or techniques that you send us for any purpose. However, we will not release your name or otherwise publicize the fact that you submitted materials or other information to us unless: (a) we obtain your permission to use your name; or (b) we first notify you that the materials or other information you submit to a particular part of this site will be published or otherwise used with your name on it; or (c) we are required to do so by law. Personally-identifiable information that you submit to Nodify for the purpose of receiving products or services will be handled in accordance with our privacy policies. Please see the tab entitled "Privacy" for information regarding Nodify’s privacy policies.
                </TextContent>

                <Title>Worldwide Availability</Title>
                <TextContent>
                  Information Nodify publishes on the World Wide Web may contain references or cross references to Nodify products, programs and services that are not announced or available in your country. Such references do not imply that Nodify intends to announce or make available such products, programs, or services in your country. Please consult Nodify for information regarding the products, programs, and services that may be available to you.
                </TextContent>

                <Title>Relations with other Businesses</Title>
                <TextContent>
                  This Web site may provide links or references to non-Nodify Web sites and resources. Nodify makes no representations, warranties, or other commitments whatsoever about any non-Nodify Web sites or third-party resources that may be referenced, accessible from, or linked to any Nodify site. A link to a non-Nodify Web site does not mean that Nodify endorses the content or use of such Web site or its owner. In addition, Nodify is not a party to or responsible for any transactions you may enter into with third parties, even if you learn of such parties (or use a link to such parties) from a Nodify site. Accordingly, you acknowledge and agree that Nodify is not responsible for the availability of such external sites or resources, and is not responsible or liable for any content, services, products, or other materials on or available from those sites or resources.
                </TextContent>
                <br/>
                <TextContent>
                  When you access a non-Nodify Web site, even one that may contain the Nodify-logo, please understand that it is independent from Nodify, and that Nodify does not control the content on that Web site. It is up to you to take precautions to protect yourself from viruses, worms, trojan horses, and other potentially destructive programs, and to protect your information as you deem appropriate.
                </TextContent>

                <Title>Links to this site</Title>
                <TextContent>
                  All links to this Web site must be approved in writing by Nodify, except that Nodify consents to links in which the link and the pages that are activated by the link do not: (a) create frames around any page on this Web site or use other techniques that alter in any way the visual presentation or appearance of any content within this site; (b) misrepresent your relationship with Nodify; (c) imply that Nodify approves or endorses you, your Web site, or your service or product offerings; and (d) present false or misleading impressions about Nodify or otherwise damage the goodwill associated with the Nodify name or trademarks. As a further condition to being permitted to link to this site, you agree that Nodify may at any time, in its sole discretion, terminate permission to link to this Web site. In such event, you agree to immediately remove all links to this Web site and to cease using any Nodify trademark.
                </TextContent>

                <Title>DISCLAIMER OF WARRANTY</Title>
                <TextContent>
                  USE OF THIS SITE IS AT YOUR SOLE RISK. ALL MATERIALS, INFORMATION, PRODUCTS, SOFTWARE, PROGRAMS, AND SERVICES ARE PROVIDED "AS IS," WITH NO WARRANTIES OR GUARANTEES WHATSOEVER. NODIFY EXPRESSLY DISCLAIMS TO THE FULLEST EXTENT PERMITTED BY LAW ALL EXPRESS, IMPLIED, STATUTORY, AND OTHER WARRANTIES, GUARANTEES, OR REPRESENTATIONS, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY AND INTELLECTUAL PROPERTY RIGHTS. WITHOUT LIMITATION, NODIFY MAKES NO WARRANTY OR GUARANTEE THAT THIS WEB SITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE. YOU UNDERSTAND AND AGREE THAT IF YOU DOWNLOAD OR OTHERWISE OBTAIN MATERIALS, INFORMATION, PRODUCTS, SOFTWARE, PROGRAMS, OR SERVICES, YOU DO SO AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES THAT MAY RESULT, INCLUDING LOSS OF DATA OR DAMAGE TO YOUR COMPUTER SYSTEM.
                </TextContent>
                <TextContent>
                  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.                  
                </TextContent>

                <Title>LIMITATION OF LIABILITY</Title>
                <TextContent>
                  IN NO EVENT WILL NODIFY BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY TYPE WHATSOEVER RELATED TO OR ARISING FROM THIS WEB SITE OR ANY USE OF THIS WEB SITE, OR OF ANY SITE OR RESOURCE LINKED TO, REFERENCED, OR ACCESSED THROUGH THIS WEB SITE, OR FOR THE USE OR DOWNLOADING OF, OR ACCESS TO, ANY MATERIALS, INFORMATION, PRODUCTS, OR SERVICES, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOST SAVINGS OR LOSS OF PROGRAMS OR OTHER DATA, EVEN IF NODIFY IS EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS EXCLUSION AND WAIVER OF LIABILITY APPLIES TO ALL CAUSES OF ACTION, WHETHER BASED ON CONTRACT, WARRANTY, TORT, OR ANY OTHER LEGAL THEORIES.                  
                </TextContent>
                  <br/><br/>
                <TextContent>
                  Additional or different terms, conditions, and notices may apply to specific materials, information, products, software, and services offered through this Web site. In the event of any conflict, such additional or different terms, conditions, and notices will prevail over these Terms of Use. Please see the applicable agreement or notice.
                </TextContent>

                <br/><br/>

                <Heading lightText={lightText}>Privacy Highlights</Heading>

                <Title>Scope</Title>
                <TextContent>
                  This statement applies to Nodify Web Sites.
                </TextContent>

                <Title>Personal Information</Title>
                <TextContent>
                  In general, you can visit us on the internet without telling us who you are or giving us personal information. There are times when we may need information from you, for instance: to process an order, to correspond, to provide a subscription or in connection with a job application. We may supplement this information to complete a transaction or to provide a better service.
                </TextContent>

                <Title>Uses</Title>
                <TextContent>
                  <ListWrapper>
                    <li>To fulfil your requests by us or by others involved in fulfilment.</li>
                    <li>To contact you for customer satisfaction surveys, market research or in connection with certain transactions.</li>
                    <li>Marketing purposes if you have permitted such use.</li>
                    <li>In an non-identifiable format for analysis your choices</li>
                  </ListWrapper>
                </TextContent>

                <Title>Your choices</Title>
                <TextContent>
                  <ListWrapper>
                    <li>To fulfil your requests by us or by others involved in fulfilment.</li>
                    <li>To contact you for customer satisfaction surveys, market research or in connection with certain transactions.</li>
                    <li>Marketing purposes if you have permitted such use.</li>
                    <li>In an non-identifiable format for analysis your choices</li>
                  </ListWrapper>
                </TextContent>

                <br/><br/>

                <Heading lightText={lightText}>Privacy implementation on the Web</Heading>
                <TextContent>
                  Your privacy is important to Nodify. This statement discloses the information practices for Nodify Web sites, including what type of information is gathered and tracked, how the information is used, and with whom the information is shared.                  
                </TextContent>

                <Title>Personal Information</Title>
                <TextContent>
                  In general, you can visit Nodify on the Internet without telling us who you are and without giving any personal information about yourself. There are times, however, when we or our partners may need information from you.
                </TextContent>
                <TextContent>
                  You may choose to give us personal information in a variety of situations. For example, you may want to give us information, such as your name and address or e-mail id, to correspond with you, to process an order, or to provide you with a subscription. You may give us your credit card details to buy something from us or a description of your education and work experience in connection with a job opening at Nodify that you wish to be considered for. We intend to let you know how we will use such information before we collect it from you; if you tell us that you do not want us to use this information to make further contact with you beyond fulfilling your requests, we will respect your wishes. If you give us personal information about somebody else, such as a spouse or work colleague, we will assume that you have their permission to do so.
                </TextContent>

                <Title>Using and Sharing Personal Information</Title>
                <TextContent>
                  Nodify may use your personal information and with whom we may share it. Depending on the type of Nodify Web site you are visiting, one or more of the paragraphs may apply. For example, if you order a product from an Nodify Web site, your information will be handled as described in such paragraphs as Fulfilling Your Transaction Request and Marketing Use.                  
                </TextContent>

                <Title>Fulfilling your transaction request</Title>
                <TextContent>
                  If you request something from a Nodify Web site, we will use the information you provide to fulfill your request. To help us do this, we may share information, with others, for instance, other parts of Nodify, Nodify's Business Partners, financial institutions, shipping companies, postal or government authorities involved in fulfillment. In connection with a transaction we may also contact you as part of our customer satisfaction surveys or for market research purposes.
                </TextContent>                
                <TextContent>
                  If you choose to "Email This Page" to a friend or colleague, we will ask for their name and email address. We will automatically send a one-time email sharing the page you indicated. Nodify does not store this information.                  
                </TextContent>  

                <Title>Marketing Use</Title>
                <TextContent>
                    The information you provide to Nodify on certain Nodify Web sites may also be used by Nodify and selected third parties for marketing purposes. Before we use it, however, we will offer you the opportunity to choose whether or not to have your information used in this way. To opt-out of receiving further marketing emails from Nodify, please follow the unsubscribe instructions included in each email.                  
                </TextContent> 

                <Title>Other Web Site Notices</Title>
                <TextContent>
                  In some cases, specific Nodify Web sites may contain other notices about their use and the information practices applicable to those sites.
                </TextContent>     

                <Title>Information Security and Quality</Title>
                <TextContent>
                    We intend to protect your personal information and to maintain its quality. We implement appropriate measures and processes, such as using encryption when transmitting certain sensitive information, to help us to keep your information secure and to maintain its quality.                  
                </TextContent>   

                <Title>Supplementing Information</Title>
                <TextContent>
                    From time to time we may supplement information you give us via a Nodify Web site with information from other sources, such as information validating your address or other available information about businesses. This is to help us maintain the accuracy of the information we collect and to help us provide a better service.                  
                </TextContent>         

                <Title>Cookies, Web Beacons and Other Technologies</Title>
                <TextContent>
                  We sometimes collect non-identifiable information from visits to our Web sites to help us provide better customer service. For example, we keep track of the domains from which people visit, and we also measure visitor activity on Nodify Web sites, but we do so in ways that keep the information non-identifiable. This information is sometimes known as "clickstream data." Nodify or others on Nodify's behalf may use this data to analyze trends and statistics and to help us provide better customer service.                  
                </TextContent>         
                  <br/>
                <TextContent>
                  Also, when we collect personal data from you in a transaction, we may extract some information about that transaction in an non-identifiable format and combine it with other non-identifiable information such as clickstream data. This information is used and analyzed only at an aggregate level to help us understand trends and patterns. This information is not reviewed at an individual level. If you do not want your transaction details used in this manner you can disable your cookies.                  
                </TextContent>                
                  <br/>
                <TextContent>
                  We collect the information we mentioned in the previous paragraphs through the use of various technologies, including one called "cookies". A cookie is a piece of data that a Web site can send to your browser, which may then be stored on your computer as an anonymous tag that identifies your computer but not you. Some Nodify pages use cookies, sent by Nodify or its third party vendors, or other technologies to better serve you when you return to the Web site. You can set your browser to notify you before you receive a cookie, giving you the chance to decide whether to accept it. You can also set your browser to turn off cookies. If you do so, however, some Web sites may not work properly.                  
                </TextContent>     
                <br/>           
                <TextContent>
                  Some Nodify Web sites also use Web beacon or other technologies to better tailor those sites to provide better customer service. These technologies may be in use on a number of pages across Nodify's Web sites. When a visitor accesses these pages, a non-identifiable notice of that visit is generated which may be processed by us or by our suppliers. These Web beacons usually work in conjunction with cookies. If you don't want your cookie information to be associated with your visits to these pages, you can set your browser to turn off cookies.                  
                </TextContent>     
                <br/>           
                <TextContent>
                  Some of our business partners (e.g., advertisers) use cookies on our site. We have no access to or control over these cookies. See "Online Advertising."                  
                </TextContent>     
                <br/>           
                <TextContent>
                If you turn off cookies, Web beacon and other technologies will still detect visits to these pages, but the notices they generate cannot be associated with other non-identifiable cookie information and are disregarded.                  
                </TextContent>                



                <Title>Service Quality Monitoring</Title>
                <TextContent>
                  Certain Web transactions may also involve you calling us or our calling you. Please be aware that it is Nodify's general practice to monitor and in some cases record such calls for staff training or quality assurance purposes.
                </TextContent>                

                <Title>Online Advertising</Title>
                <TextContent>
                  Certain companies help us deliver interactive on-line advertising such as banner ads. These companies may collect and use information about customers to help us better understand the offers, promotions, and types of advertising that are most appealing to our customers. After it is collected the information is aggregated so it is not identifiable to a specific individual. If, however, you would prefer that these companies not collect such information, please visit www.networkadvertising.org and follow the "opt-out" instructions.                  
                </TextContent>                

                <Title>Personalized URL Link</Title>
                <TextContent>
                  On occasion, we may personalize and customize Web sites for certain visitors. If you visit one of these sites, you may find it customized with references to products and/or services that we believe may be of interest to you, based on your previous interactions with Nodify and information you have provided to us. While you are visiting these Web sites, we may collect information about your visit to better tailor the site to your interests. An invitation to visit one of these Web sites is usually presented as a personalized URL in an e-mail, a notice on a Web site registration page, or as a response to you logging on to a certain Web site. If you choose to visit one of these Web sites, you are agreeing to let Nodify collect information about your visits and also to its association with other information about you and your relationship with Nodify. If you do not wish your information to be used in this way, do not accept the invitation to visit these sites.
                </TextContent>                

                <Title>Disclosures Required By Law</Title>
                <TextContent>
                  Please be aware that in certain circumstances, it is possible that personal information may be subject to disclosure pursuant to judicial or other government subpoenas, warrants, or orders.
                </TextContent>       

                <Title>Links to non-Nodify Web sites</Title>
                <TextContent>
                  Nodify Web sites may contain links to other Web sites. Nodify is not responsible for the privacy practices or the content of those other Web sites.                  
                </TextContent>                



                Personalized URL Link

              </TextWrapper>
            </Column1>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
      <WarningLayer type={formValue.showWarningType} message={formValue.showWarningMessage} show={warning} stateChanger={showWarning} />
      <LoadingLayer show={show} />
    </>
  )
}

export default ContactPopup;