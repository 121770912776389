import React,{useState} from 'react'
import {Button} from '../button/button.style.js';
import {
  ArrowForward,
  ArrowRight,
} from '../herosection/herosection.style';

import { 
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  Img2,
  SolutionItemWrapper,
  SolutionItem,
  ApproachDetails,
  ApproachDetailTitle,
  ApproachSolutionWrapper,
  SolutionColumn1,
  SolutionColumn2,
  WhatWeOfferItemWrappper,
  WhatWeOfferWrapper,
  ItemIcon,
  ItemTitle,
  ItemDescription,
  InfoContainer2,
  InfoWrapper2,
  SolutionItemPreWrapper,
  SolutionPreItem,
  MoreInfoWrapper,
  IframeContainer
  
 } from './whatweoffer.style';

const WhatWeOffer = ({lightBg,id,imgStart,lightText,darkText}) => {
  const [hover,setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  }

  return (
    <>
    <div id={id}>
      <InfoContainer lightBg={lightBg} >
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>What We Offer</TopLine>
                <Heading lightText={lightText}>Efficiency and simplicity by design</Heading>
                <Subtitle darkText={darkText}>We offer a comprehensive framework that can be used to design complex workflows to run the business with increased efficiency.</Subtitle>
                {
                  /*
                <Subtitle darkText={darkText}>
                  This framework is; 
                </Subtitle>                  
                  */
                }

                <SolutionItemPreWrapper>
                  <SolutionPreItem><span>Easy-to-install :</span> the application becomes ready for implementation in a few hours.</SolutionPreItem>
                  <SolutionPreItem><span>Easy-to-implement :</span> by using our smart configuration tools like Visual Workflow Designer and Form Designer, complex workflows can be designed for any business process with no-code/low-code. There is no need to have technical background or knowledge.</SolutionPreItem>
                  <SolutionPreItem><span>Easy-to-customize :</span> our platform provides the flexibility to implement the tailor-made workflows and forms easily.</SolutionPreItem>
                  <SolutionPreItem><span>Easy-to-change :</span> any change requests on the flows shall be easy implemented. </SolutionPreItem>
                  <SolutionPreItem><span>Easy-to-integrate :</span> we offer ready to use API's and adapters to make it easy to integrate with external systems like ERP's or BI tools.</SolutionPreItem>
                  <SolutionPreItem><span>Easy-to-deploy :</span> with our three layer approach, any flows shall be deployed instantly with flow versioning functionality.</SolutionPreItem>
                  <SolutionPreItem><span>Easy-to-operate :</span> daily administration tasks – management of users, roles and permissions - shall be easily handled by our admin panel.</SolutionPreItem>
                  <SolutionPreItem><span>Easy-to-use :</span> our simplified and intuitive interface allows the users to start using the platform with minimum effort both on web and mobile.</SolutionPreItem>
                  <SolutionPreItem><span>Easy-to-learn :</span> our guides and tutorials help to learn how to implement.</SolutionPreItem>
                </SolutionItemPreWrapper>                                  
                <Subtitle darkText={darkText}>Our built-in template processes shall be used as the starting point for various business requirements immediately.</Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img2 src={require('../../assets/images/efficiency-simplicity.png')} alt="What we offer"/>
              </ImgWrap>
            </Column2>
          </InfoRow>
          <IframeContainer>
              <iframe src="https://www.youtube-nocookie.com/embed/C5IIkz7pxDo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </IframeContainer>          
          {

          /*
          
        */ 
          }          
        </InfoWrapper>
      </InfoContainer>
      <InfoContainer2 lightBg={lightBg}>
        <InfoWrapper2>

        <ApproachDetails>
        <ApproachDetailTitle>Built in Solutions & Processes</ApproachDetailTitle>

        <WhatWeOfferWrapper>
          <WhatWeOfferItemWrappper>
            <ItemIcon src='/assets/images/icon1.png' alt="Request for Quote (RFQ) Flow" />
            <ItemTitle>Request for Quote (RFQ) Flow</ItemTitle>
            <ItemDescription>Creation of the RFQ document allows for the selection of products and suppliers from the ERP tables. It can then be routed to management for approval prior to distribution for quotation. The data from the quotation received, is captured and systemised calculation of the best price offered by the suppliers that responded, is displayed, and the RFQ is routed for approval of the preferred supplier.</ItemDescription>
          </WhatWeOfferItemWrappper>
          <WhatWeOfferItemWrappper>
            <ItemIcon src='/assets/images/icon2.png' alt="Purchase Request Flow (PR)" />
            <ItemTitle>Purchase Request Flow (PR) </ItemTitle>
            <ItemDescription>The purchase request flow can be used with or without an RFQ being distributed. Where quotes are received without an RFQ, the user selects the quoted products from the ERP product catalogue, Prices and delivery details are updated and the system calculates the best price based on historical prices in the ERP. The PR is the routed for approval of the preferred supplier and signatures placed on the PR documents. With integration, the system can create and distribute the PO. All related documents are stored in the Document Management system for display in the ERP.</ItemDescription>
          </WhatWeOfferItemWrappper>
          <WhatWeOfferItemWrappper>
            <ItemIcon src='/assets/images/icon3.png' alt="Supplier Invoice Approval Flow" />
            <ItemTitle>Supplier Invoice Approval Flow</ItemTitle>
            <ItemDescription>Supplier invoices for Open orders listed from the ERP, are validated against the Purchase Order by comparing the order details with a preview of the uploaded Supplier Invoices. It is then routed for approval. Once approved, it triggers or notifies Finance that payment is authorised. It also handles multiple supplier invoices against a single purchase order. With integration, it can update the ERP Purchase Invoice and Payment fields.</ItemDescription>
          </WhatWeOfferItemWrappper>
          <WhatWeOfferItemWrappper>
            <ItemIcon src='/assets/images/icon4.png' alt="Customer Onboarding" />
            <ItemTitle>Customer Onboarding</ItemTitle>
            <ItemDescription>Data extracted from an online portal customer application form, or the workflow online form, together with necessary documents uploaded, is routed to credit control for validation.  In the workflow, a credit limit is defined and approved. Warrantees, Guarantees, Terms and Conditions, contracts and more, are templatised and data inserted systematically. The templates can include electronic signatures or be digitally signed with integrated DocuSign templates. Once approved the Customer account is opened in the ERP.</ItemDescription>
          </WhatWeOfferItemWrappper>
          <WhatWeOfferItemWrappper>
            <ItemIcon src='/assets/images/icon5.png' alt="Supplier Onboarding" />
            <ItemTitle>Supplier Onboarding</ItemTitle>
            <ItemDescription>Data extracted from an online portal supplier application form, together with the necessary documents uploaded, is routed through Accounts Payable for vetting.  In the workflow a transaction limit is defined and approved. Warrantees, Guarantees, Terms and Conditions, contracts and more, are templatised and data inserted systematically. The templates can either include electronic signatures or be digitally signed with integrated DocuSign templates. Once approved the customer account is opened in the ERP.</ItemDescription>
          </WhatWeOfferItemWrappper>   
            <WhatWeOfferItemWrappper>
            <ItemIcon src='/assets/images/icon6.png' alt="Expense Claims" />
            <ItemTitle>Expense Claims</ItemTitle>
            <ItemDescription>The Expense claim module offers several sub-flows depending on the client’s needs, but is largely used for entertainment, and local travel. It includes the type of claim, description, date, names of those being entertained, their company’s name, reason, cost, gratuity (maximum levels set), distance travelled, Google maps showing the route, receipts, supporting documents and reports are also uploaded. Save the daily claims in “Drafts” until month end and submit the total claim for approval.</ItemDescription>
          </WhatWeOfferItemWrappper>    
          
          <WhatWeOfferItemWrappper>
            <ItemIcon src='/assets/images/icon7.png' alt="Travel Claim" />
            <ItemTitle>Travel Claim</ItemTitle>
            <ItemDescription>This sub flow can be implemented as a standalone workflow. It manages local travel, accommodation and expenses including Google Map insert to display the routes and distances travelled, systemised cost calculations based on the company’s cost per km/mile allowance, receipts and supporting document uploads. This can be routed for approval after the event or saved in “Drafts” until month end to submit a total claim for approval.</ItemDescription>
          </WhatWeOfferItemWrappper> 
          <WhatWeOfferItemWrappper>
            <ItemIcon src='/assets/images/icon8.png' alt="Travel Schedule Flow" />
            <ItemTitle>Travel Schedule Flow</ItemTitle>
            <ItemDescription>Most organisations budget for expected travel especially international travel, so the need arises for a detailed travel schedule that can be approved before the budgeting cycle and revised quarterly to have a more realistic view of scheduled travel expenses. Once the schedule is approved, it may be used to pre-book clients and travel arrangements as the schedule is deemed to be a pre-approval until the final application for travel is submitted.</ItemDescription>
          </WhatWeOfferItemWrappper> 
          <WhatWeOfferItemWrappper>
            <ItemIcon src='/assets/images/icon9.png' alt="International Travel Flow" />
            <ItemTitle>International Travel Flow</ItemTitle>
            <ItemDescription>This sub flow can also be implemented as a standalone workflow, connected to the Travel schedule. It manages international travel, accommodation and expenses including the application to travel, systemised cost calculations versus budget based on the approved schedule Travel schedule. Full justification for the trip, tickets, hotel booking etc are uploaded in support of the application. Subsequently receipts and trip reports are loaded for the Executive to review.</ItemDescription>
          </WhatWeOfferItemWrappper> 


        </WhatWeOfferWrapper>
        <ApproachDetailTitle>Other typical workflows that have been deployed and regarded critical in most organisations</ApproachDetailTitle>
        <ApproachSolutionWrapper>
          <SolutionColumn1>
            <SolutionItemWrapper>
              <SolutionItem>IT user request </SolutionItem>
              <SolutionItem>Tender preparation where multiple participants need to add orapprove documents</SolutionItem>
              <SolutionItem>Consignment stock management </SolutionItem>
              <SolutionItem>Repairs &Maintenance </SolutionItem>
              <SolutionItem>Contracts management </SolutionItem>
              <SolutionItem>Customer Complaints &Issues management</SolutionItem>
              <SolutionItem>Task management </SolutionItem>
              <SolutionItem>Forex management  </SolutionItem>
              <SolutionItem>Safety, Health, Environment and Quality</SolutionItem>
              <SolutionItem>Risk Management</SolutionItem>
            </SolutionItemWrapper>
          </SolutionColumn1>
          <SolutionColumn2>
            <SolutionItemWrapper>
              <SolutionItem>Technical Departments </SolutionItem>
              <SolutionItem>GDPR – Europe’s General Data Protection Regulations </SolutionItem>
              <SolutionItem>Budgeting</SolutionItem>
              <SolutionItem>Budgeting over expenditure flow </SolutionItem>
              <SolutionItem>HR-approval of new positions at the company </SolutionItem>
              <SolutionItem>HR-candidate employee review and approval </SolutionItem>
              <SolutionItem>HR-staff onboarding </SolutionItem>
              <SolutionItem>HR-staff access card </SolutionItem>
              <SolutionItem>Management of company assets</SolutionItem>
              <SolutionItem>IT services</SolutionItem>
            </SolutionItemWrapper>
          </SolutionColumn2>
        </ApproachSolutionWrapper>
      </ApproachDetails>         
          <MoreInfoWrapper>
            <Button
               to="contact"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              smooth={true}
              duration={500}
              spy={true}
              primary={true}
              dark={false}>
              For More Information {hover ? <ArrowForward /> : <ArrowRight/>}
            </Button>        
          </MoreInfoWrapper>        
        </InfoWrapper2> 
      </InfoContainer2>  
    </div>
    </>
  )
}

export default WhatWeOffer;