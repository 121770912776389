import React, { useState } from 'react'
import { Button } from '../button/button.style.js';
import axios from 'axios';
import { FaLinkedin, FaYoutube } from 'react-icons/fa';
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  SocialIcons,
  SocialIconLink,
  AddressWrapper,
  HeadQuarter,
  AddressTitle,
  AddressDetail,
  Title,
  TechnicalCenter,
  UKOffice
} from './becomeapartner.style';
import WarningLayer from '../warninglayer/warninglayer.component.jsx';
import LoadingLayer from '../loadinglayer/loadinglayer.component.jsx';


const BecomeAPartner = ({ lightBg, id, imgStart, lightText, darkText }) => {
  const [formValue, setFormValue] = useState({
    name: '',
    title: '',
    company: '',
    email: '',
    message: '',
    showWarningMessage: '',
    showWarningType: 1
  });

  const [show, setShow] = useState(false);
  const [warning, setWarning] = useState(false);
  const [timerid, setTimerid] = useState(null);

  const showWarning = (type, message, show) => {
    try {
      clearTimeout(timerid);
    } catch (ex) { }

    setFormValue(
      {
        ...formValue,
        showWarningMessage: message,
        showWarningType: type
      }
    );
    setWarning(show);
    const timer = () => setTimeout(() => {
      setWarning(false);
    }, 3000);
    setTimerid(timer());

  }




  const handleSend = async (event) => {
    setShow(true);
    const fmData = new FormData();
    let warnmessage = "";
    if (formValue.name.trim() === "" || formValue.name === null || formValue.name === undefined) {
      warnmessage = "Please check the full name field.";
      showWarning(1, warnmessage, true);
      setShow(false);
      return;
    } else {
      fmData.append("name", formValue.name);
    }

    if (formValue.title.trim() === "" || formValue.title === null || formValue.title === undefined) {
      warnmessage = "Please check the job title field.";
      showWarning(1, warnmessage, true);
      setShow(false);
      return;
    } else {
      fmData.append("title", formValue.title);
    }

    if (formValue.phone.trim() === "" || formValue.phone === null || formValue.phone === undefined) {
      warnmessage = "Please check the phone field.";
      showWarning(1, warnmessage, true);
      setShow(false);
      return;
    } else {
      fmData.append("phone", formValue.phone);
    }    

    if (formValue.company.trim() === "" || formValue.company === null || formValue.company === undefined) {
      warnmessage = "Please check the company field.";
      showWarning(1, warnmessage, true);
      setShow(false);
      return;
    } else {
      fmData.append("company", formValue.company);
    }

    if (formValue.website.trim() === "" || formValue.website === null || formValue.website === undefined) {
      warnmessage = "Please check the company website field.";
      showWarning(1, warnmessage, true);
      setShow(false);
      return;
    } else {
      fmData.append("website", formValue.website);
    }

    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(formValue.email)) {
      warnmessage = "Please check the email address.";
      showWarning(1, warnmessage, true);
      setShow(false);
      return;
    } else {
      fmData.append("email", formValue.email);
    }

    if (formValue.message.trim() === "" || formValue.message === null || formValue.message === undefined) {
      warnmessage = "Please check the message field.";
      showWarning(1, warnmessage, true);
      setShow(false);
      return;
    } else {
      fmData.append("message", formValue.message);
    }

    const config = {
      headers: { "content-type": "multipart/form-data" },
      crossDomain: true
    }

    const res = await axios.post(
      "send-reseller-form",
      fmData,
      config
    );
    if (res.data.type === "done") {
      warnmessage = "Become Our Reseller form successfully sent!";
      showWarning(0, warnmessage, true);
      setFormValue({
        ...formValue,
        name: '',
        title: '',
        phone: '',
        company: '',
        website: '',
        email: '',
        message: '',
        showWarningMessage: warnmessage,
        showWarningType: 0
      })
      setShow(false);
    } else {
      warnmessage = "An error has occured. Please try again";
      showWarning(1, warnmessage, true);
      setShow(false);
    }

  }

  const handleChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value
    });
    const tmp_name = "label-"+event.target.name;
    const el = document.getElementsByClassName(tmp_name)[0];
    if (event.target.value.length > 0){
      el.classList.remove('slide-down');
      el.classList.add('slide-up');
    }else{
      el.classList.remove('slide-up');
      el.classList.add('slide-down');
    }
    
  }

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>PARTNER</TopLine>
                <Heading lightText={lightText}>Become a Business Partner</Heading>
                <Subtitle darkText={darkText}>We are a partner driven business, helping our partners offer strong, flexible and profitable solutions to their customers
                  If you are a software reseller and are interested in offering our value adding solutions to your customers, become Nodify Business Partner today!</Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <div className="background">
                <div className="container">
                  <div className="screen">
                    <div className="screen-body">
                      <div className="screen-body-item">
                        <div className="app-form">
                          <div className="app-form-group-sub">
                            <span className="label-form label-name">Full Name :</span>
                            <input type="text" name="name" className="app-form-control-sub" placeholder="Full Name" value={formValue.name} onChange={handleChange} />
                          </div>
                          <div className="app-form-group-sub">
                            <span className="label-form label-title">Job Title :</span>
                            <input type="text" name="title" className="app-form-control-sub" placeholder="Job Title" value={formValue.title} onChange={handleChange} />
                          </div>
                          <div className="app-form-group-sub">
                            <span className="label-form label-email">Email :</span>
                            <input type="email" name="email" className="app-form-control-sub" placeholder="Email" value={formValue.email} onChange={handleChange} />
                          </div>
                          <div className="app-form-group-sub">
                            <span className="label-form label-phone">Phone :</span>
                            <input type="text" name="phone" className="app-form-control-sub" placeholder="Phone" value={formValue.phone} onChange={handleChange} />
                          </div>

                          <div className="app-form-group-sub">
                            <span className="label-form label-company">Company Name :</span>
                            <input type="text" name="company" className="app-form-control-sub" placeholder="Company Name" value={formValue.company} onChange={handleChange} />
                          </div>

                          <div className="app-form-group-sub">
                            <span className="label-form label-website">Company Website :</span>
                            <input type="text" name="website" className="app-form-control-sub" placeholder="Company Website" value={formValue.website} onChange={handleChange} />
                          </div>

                          <div className="app-form-group-textarea-sub message">
                            <span className="label-form label-message">Message :</span>
                            <textarea name="message" className="app-form-control-sub" placeholder="Message" value={formValue.message} onChange={handleChange} />
                          </div>
                          <p className="formInfoSub">All fields are required.</p>
                          <BtnWrap>
                            <Button
                              to='home'
                              smooth={true}
                              duration={500}
                              spy={true}
                              exact="true"
                              offset={-80}
                              onClick={handleSend}
                            >Submit
                            </Button>
                          </BtnWrap>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
      <WarningLayer type={formValue.showWarningType} message={formValue.showWarningMessage} show={warning} stateChanger={showWarning} />
      <LoadingLayer show={show} />
    </>
  )
}

export default BecomeAPartner;