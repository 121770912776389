import React,{ useEffect, useState} from 'react';
import  { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import {animateScroll as scroll} from 'react-scroll';

import {
  Nav,
  NavBarContainer,
  NavLogo,
  LogoIMG,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtnLinkSub,
  NavButtonWrapper
} from './navbar.style';



const NavBar = ({toggle,reactga}) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [hover,setHover] = useState(false);


  const changeNav = () => {
    if (window.scrollY >= 120){
      setScrollNav(true);
    }else{
      setScrollNav(false);
    }
  }

  useEffect(()=>{
    window.addEventListener('scroll',changeNav)
  },[])

  const onSetActive = (to) => {
    //console.log(to);
    reactga.pageview(to);
  }
  

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  const onHover = () => {
    setHover(!hover);
  }

  const goToBlog = () => {
    window.location = 'https://blog.nodify.tech';
  }


  return (
    <>
    <IconContext.Provider value={{color:'#951f76'}}>
      <Nav scrollNav={scrollNav}>
        <NavBarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            <LogoIMG scrollNav={scrollNav} src='/assets/images/nodify-logo.png' alt="Nodify Tech" />
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu scrollNav={scrollNav}>
            <NavItem scrollNav={scrollNav}>
              <NavLinks
                to="about"
                smooth={true}
                duration={500}
                spy={true}
                offset={-80}
                scrollNav={scrollNav}
                onSetActive={onSetActive}
              >About
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="team"
                smooth={true}
                duration={500}
                spy={true}
                offset={-80}
                scrollNav={scrollNav}
                onSetActive={onSetActive}
              >Team
              </NavLinks>
            </NavItem>                        
            <NavItem>
              <NavLinks 
                to="approach"
                smooth={true}
                duration={500}
                spy={true}
                offset={-80}                
                scrollNav={scrollNav}
                onSetActive={onSetActive}
                >Our Approach
              </NavLinks>
            </NavItem>            
            <NavItem>
              <NavLinks 
                to="whatweoffer"
                smooth={true}
                duration={500}
                spy={true}
                offset={-80}      
                scrollNav={scrollNav}
                onSetActive={onSetActive}  
              >What We Offer</NavLinks>
            </NavItem>            
            {
              /*
              <NavItem>
              <NavLinks
                to="theplatform"
                smooth={true}
                duration={500}
                spy={true}
                offset={-80}
                scrollNav={scrollNav}
              >The Platform</NavLinks>
            </NavItem>            
            */
            }


            <NavItem>
              <NavLinks
                to="contact"
                smooth={true}
                duration={500}
                spy={true}
                offset={-80}
                scrollNav={scrollNav}
                onSetActive={onSetActive}
              >Contact</NavLinks>            
            </NavItem>    
            <NavItem>
              <NavLinks
                to="https://blog.nodify.tech"
                smooth={true}
                onClick={goToBlog}
              >Blog</NavLinks>            
            </NavItem>    
            <NavItem>
              <NavButtonWrapper>
                <NavBtnLinkSub
                  to="become-a-partner"
                  onMouseEnter={onHover}
                  onMouseLeave={onHover}
                  smooth={true}
                  duration={500}
                  spy={true}
                  primary={false}
                  dark={false}>
                  Become a Partner
                </NavBtnLinkSub>  
              </NavButtonWrapper>         
            </NavItem>                                
          </NavMenu>
        </NavBarContainer>
      </Nav>    
    </IconContext.Provider>
    </>
  )
}

export default NavBar;