import React,{ useState } from 'react'
import {Button} from '../button/button.style.js';
import axios from 'axios';
import { FaLinkedin, FaYoutube } from 'react-icons/fa';
import { 
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  SocialIcons,
  SocialIconLink,
  AddressWrapper,
  HeadQuarter,
  AddressTitle,
  AddressDetail,
  Title,
  TechnicalCenter,  
  UKOffice
 } from './contact.style';
import WarningLayer from '../warninglayer/warninglayer.component.jsx';
import LoadingLayer from '../loadinglayer/loadinglayer.component.jsx';


const Contact = ({lightBg,id,imgStart,lightText,darkText}) => {
  const [formValue, setFormValue] = useState({
    name: '',
    title: '',
    company: '',
    email: '',
    message: '',
    showWarningMessage:'',
    showWarningType:1
  });

  const [show, setShow] = useState(false);
  const [warning, setWarning] = useState(false);
  const [timerid, setTimerid] = useState(null);
  
  const showWarning = (type,message,show) => {
    try{
      clearTimeout(timerid);
    }catch(ex){}
    
    setFormValue(
      {
        ...formValue,
        showWarningMessage:message,
        showWarningType:type
      }
    );
    setWarning(show);
    const timer = () => setTimeout(()=>{
      setWarning(false);
    },3000);
    setTimerid(timer());

  }

   


  const handleSend = async (event) => {
    setShow(true);
    const fmData = new FormData();
    let warnmessage = "";
    if (formValue.name.trim() === "" || formValue.name === null || formValue.name === undefined){
      warnmessage = "Please check the name field.";
      showWarning(1,warnmessage,true);
      setShow(false);
      return;
    }else{
      fmData.append("name", formValue.name);
    }

    if (formValue.title.trim() === "" || formValue.title === null || formValue.title === undefined){
      warnmessage = "Please check the title field.";
      showWarning(1,warnmessage,true);
      setShow(false);
      return;
    }else{
      fmData.append("title", formValue.title);
    }

    if (formValue.company.trim() === "" || formValue.company === null || formValue.company === undefined){
      warnmessage = "Please check the company field.";
      showWarning(1,warnmessage,true);
      setShow(false);
      return;
    }else{
      fmData.append("company", formValue.company);
    }

    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(formValue.email)) {
      warnmessage = "Please check the email address.";
      showWarning(1,warnmessage,true);
      setShow(false);
      return;
    }else{
      fmData.append("email", formValue.email);
    }

    if (formValue.message.trim() === "" || formValue.message === null || formValue.message === undefined){
      warnmessage = "Please check the message field.";
      showWarning(1,warnmessage,true);
      setShow(false);
      return;
    }else{
      fmData.append("message", formValue.message);
    }

    const config = {
      headers: { "content-type": "multipart/form-data" },
      crossDomain: true
    }

    const res = await axios.post(
      "send-contact-form",
      fmData,
      config
    );
    if (res.data.type === "done"){
      warnmessage = "Contact form successfully sent!";
      showWarning(0,warnmessage,true);      
      setFormValue({
        ...formValue,
        name:'',
        title:'',
        company:'',
        email:'',
        message:'',
        showWarningMessage:warnmessage,
        showWarningType:0        
      })
      setShow(false);
    }else{
      warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";
      showWarning(1,warnmessage,true);      
      setShow(false);
    }    

  }

  const handleChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value
    });
  }

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>Contact</TopLine>
                <Heading lightText={lightText}>We’d like to hear from you.</Heading>
                <Subtitle darkText={darkText}>In order to contact us, please complete and send the form or directly send an email through <b>info@nodify.tech</b> ; we will reply as soon as possible..</Subtitle>
                <SocialIcons>
                  <SocialIconLink href="//www.linkedin.com/company/nodify-tech/?viewAsMember=true" target="_blank" aria-label="Linkedin">
                    <FaLinkedin />
                  </SocialIconLink>                                                        
                  <SocialIconLink href="//www.youtube.com/channel/UCV4y7a8pR0xU4v3_-i0NaHw" target="_blank" aria-label="YouTube">
                    <FaYoutube />
                  </SocialIconLink>
              </SocialIcons>                

              <AddressWrapper>
                <TechnicalCenter>
                  <Title>Head Quarter / Technical Excellence Center</Title>
                  <AddressTitle>Izmir Technology Park R&D Center</AddressTitle>
                  <AddressDetail>1/17/51 Gulbahce Urla / Izmir / TURKEY</AddressDetail>                
                </TechnicalCenter>                
                <UKOffice>
                  <Title>UK Office</Title>
                  <AddressTitle>2 Devonshire Square</AddressTitle>
                  <AddressDetail>London EC2M 4UJ</AddressDetail>
                </UKOffice>                
                <HeadQuarter>
                  <Title>Branch Office</Title>
                  <AddressTitle>Quick Tower</AddressTitle>
                  <AddressDetail>Icerenkoy Mah. Umut Sk. No: 10-12/70 Atasehir / Istanbul / TURKEY</AddressDetail>
                </HeadQuarter>                
              </AddressWrapper>

              </TextWrapper>
            </Column1>
            <Column2>
            <div className="background">
            <div className="container">
              <div className="screen">
                <div className="screen-body">
                  <div className="screen-body-item">
                    <div className="app-form">
                      <div className="app-form-group">
                        <input type="text" name="name" className="app-form-control" placeholder="Name" value={formValue.name} onChange={handleChange} />
                      </div>
                      <div className="app-form-group">
                        <input type="text" name="title" className="app-form-control" placeholder="Title" value={formValue.title} onChange={handleChange} />
                      </div>

                      <div className="app-form-group">
                        <input type="text" name="company" className="app-form-control" placeholder="Company" value={formValue.company} onChange={handleChange} />
                      </div>
                      <div className="app-form-group">
                        <input type="email" name="email" className="app-form-control" placeholder="Email" value={formValue.email} onChange={handleChange} />
                      </div>
                      <div className="app-form-group-textarea message">
                        <textarea name="message" className="app-form-control" placeholder="Message" value={formValue.message} onChange={handleChange} />
                      </div>
                      <p className="formInfo">All fields are required.</p>
                      <BtnWrap>
                        <Button
                          to='home'
                          smooth={true}
                          duration={500}
                          spy={true}
                          exact="true"
                          offset={-80}
                          onClick={handleSend}
                        >Submit
                        </Button>
                    </BtnWrap>                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
      <WarningLayer type={formValue.showWarningType} message={formValue.showWarningMessage} show={warning} stateChanger={showWarning} />
      <LoadingLayer show={show} />
    </>
  )
}

export default Contact;