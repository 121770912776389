import styled from 'styled-components';
import TeamImgMask from '../teamimgmask/teamimgmask.component';

export const InfoContainer = styled.div`
  position:relative;
  color:#951f76;
  background: #fff;

  @media screen and (max-width:990px){
    /* padding:0px 0; */
  }

`;

export const InfoWrapper = styled.div`
  display:grid;
  z-index:1;
  width:100%;
  max-width:1400px;
  margin-right:auto;
  margin-left:auto;
  padding:0 40px;
  padding-top:80px;
  padding-bottom:80px;  
  justify-content:center;
  
  @media screen and (max-width: 990px){
    max-width:800px;
    padding-bottom:40px;
  }

  @media screen and (max-width: 375px){
    padding:20px;
  }    


`;

export const InfoRow = styled.div`
  display:grid;
  width:100%;
  grid-auto-columns: minmax(auto, auto);
  align-items:center;
  grid-template-areas: 'col1 col2';
  /* margin-bottom:70px; */


  @media screen and (max-width: 990px){
    grid-template-areas: 'col1' 'col2'
  }


`;

export const Column1 = styled.div`
  margin-bottom:15px;
  padding:0 15px;
  grid-area: col1;
  max-width:500px;
  display:flex;

  @media screen and (max-width: 990px){
    max-width:100%;
  }

  @media screen and (max-width: 680px){
    padding:0;
  }  




`;
export const Column2 = styled.div`
  margin-bottom:15px;
  padding:0 15px;
  grid-area: col2;
  width:100%;
  display:flex;
  flex-wrap:wrap;
  min-width:100%;
  justify-content:center;
`;

export const TextWrapper = styled.div`
  /* max-width:540px; */
  padding-top:0;
  padding-bottom:60px;
  @media screen and (max-width: 990px){
    padding-bottom:20px;
  }  
`;

export const TopLine = styled.p`
  color:#951f76;
  font-size:16px;
  line-height:16px;
  font-weight:700;
  letter-spacing:1.4px;
  text-transform:uppercase;
  margin-bottom:16px;
`;

export const Heading = styled.h1`
  color:#d14e3a;
  margin-bottom:24px;
  font-size:48px;
  line-height:1.1;
  font-weight:600;
  color: ${({lightText})=>(lightText ? '#f7f8fa' : '#d14e3a')};

  @media screen and (max-width: 990px){
    font-size:2rem;
  }

`;

export const Subtitle = styled.p`
  /* max-width:440px; */
  margin-bottom:35px;
  font-size:18px;
  line-height:24px;
  color: #696969;
  text-align:justify;
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content:flex-start;
`;

export const ImgWrap = styled.div`
  /* max-width:555px; */
  height:100%;
`;

export const Img = styled.img`
  width:100%;
  margin:0 0 10px 0;
  padding-right:0px;
`;

export const TeamWrapper = styled.div`
  display:flex;
  width:100%;
  justify-content:center;
  flex-wrap:wrap;

`;

export const TeamItem = styled.div`
  position: relative;
  display: flex;
  align-items:center;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  box-sizing:border-box;
  /* border: 1px solid rgba(0,0,0,.125); */
  border-radius: 0.25rem;
  width:220px;
  padding:20px;

  &:hover{
    cursor:pointer;


  }

  @media screen and (min-width: 991px) and (max-width:1279px){
    width: 160px;
  }

  @media screen and (max-width: 990px){
    width:250px;
  }  

`;

export const TeamImg = styled.img`
  max-width: 100%;
  transition: all 0.5s ease;
  border-radius: 50%!important;  
  /* height: 180px; */
  width: 180px;
  box-shadow: 0 0 3px rgb(60 72 88 / 15%) !important;
  
  @media screen and (min-width: 991px) and (max-width:1279px){
    width: 120px;
  }  
  
`;

export const TeamTitle = styled.h4`
  margin-top:20px;
  margin-bottom:3px;
  text-align:center;
  
  @media screen and (min-width: 991px) and (max-width:1279px){
    text-align:center;
  }  
`;

export const TeamPosition = styled.h5`
  margin-top:0px;
  color:#b282a1;
  text-align:center;

`;

export const TeamLink = styled.p`
  margin-top:10px;
`;



