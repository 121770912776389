import styled from 'styled-components';

export const IframeContainer = styled.div`
  position:relative;
  width:100%;
  display:flex;
  justify-content:center;
  padding-top:60px;

  iframe{
    width:100%;
    max-width:560px;
    min-height:315px;
    height:auto;
  }

`;

export const InfoContainer = styled.div`
  position:relative;
  color:#696969;
  background:#f2f2f2;

  @media screen and (max-width:990px){
    padding:0px;
  }


`;

export const InfoContainer2 = styled.div`
  position:relative;
  color:#696969;
  background-image:url('../../assets/images/nodesbg.png');
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center;

  @media screen and (max-width:990px){
    padding:0px 0;
    max-width:800px;
    margin-left:auto;
    margin-right:auto;
  }

`;

export const InfoWrapper = styled.div`
  display:grid;
  z-index:1;
  /* height:860px; */
  /* height:760px; */
  width:100%;
  max-width:1400px;
  margin-right:auto;
  margin-left:auto;
  padding:0 24px;
  justify-content:center;
  padding-top:120px;
  padding-bottom:120px;

  @media screen and (max-width:990px){
    padding:40px;
    padding-top:80px;
    padding-bottom:80px;
    max-width:800px;
  }

  @media screen and (max-width: 680px){
    padding:20px;
    padding-bottom:80px;
  }    

  @media screen and (max-width: 375px){
    padding:10px;
    padding-bottom:80px;
  }    

`;

export const InfoWrapper2 = styled.div`
  display:grid;
  z-index:1;
  width:100%;
  max-width:1400px;
  margin-right:auto;
  margin-left:auto;
  padding:0 24px;
  justify-content:center;
  padding-top:120px;
  padding-bottom:120px;

  @media screen and (max-width:990px){
    padding:40px;
    padding-top:80px;
    padding-bottom:80px;
  }  

  @media screen and (max-width: 375px){
    padding:20px;
    padding-top:80px;
    padding-bottom:80px;
  }  

`;


export const InfoRow = styled.div`
  display:grid;
  width:100%;
  grid-auto-columns: minmax(auto, 1fr);
  align-items:center;
  grid-template-areas: 'col2 col1';
  /* margin-bottom:70px; */


  @media screen and (max-width: 990px){
    grid-template-areas: 'col1' 'col2'
  }

`;

export const Column1 = styled.div`
  /* margin-bottom:15px; */
  padding:0 15px;
  grid-area: col1;
`;
export const Column2 = styled.div`
  /* margin-bottom:15px; */
  padding:0 15px;
  grid-area: col2;
  display: flex;
  align-items:baseline;
  height:100%;

  @media screen and (max-width: 990px){
    justify-content:center;
  }
  

`;

export const TextWrapper = styled.div`
  max-width:100%;
  padding-top:0;
  /* padding-bottom:60px; */
`;

export const TopLine = styled.p`
  color:#951f76;
  font-size:16px;
  line-height:16px;
  font-weight:700;
  letter-spacing:1.4px;
  text-transform:uppercase;
  margin-bottom:16px;
`;

export const Heading = styled.h1`
  color:#d14e3a;
  margin-bottom:24px;
  font-size:48px;
  line-height:1.1;
  font-weight:600;
  color: ${({lightText})=>(lightText ? '#f7f8fa' : '#d14e3a')};

  @media screen and (max-width: 990px){
    font-size:32px;
  }

  @media screen and (max-width: 480px){
    font-size:28px;
  }
`;

export const Subtitle = styled.p`
  max-width:700px;
  margin-bottom:35px;
  font-size:18px;
  line-height:24px;
  color:#696969;

  @media screen and (max-width: 990px){
    font-size:16px;
  }

`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content:flex-start;
`;

export const ImgWrap = styled.div`
  max-width:700px;
  display: flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  height:100%;

    

`;

export const Img = styled.img`
  display:block;
  width: 100%;
  max-width: 500px;
  max-height:400px;
  height: auto;
  margin:0 0 10px 0;
  padding-right:0px;
  margin-top:60px;


  @media screen and (max-width:990px){
    margin-top:50px;
    
  }

`;

export const Img2 = styled.img`
  display:flex;
  width: 100%;
  max-width: 516px;
  max-height:515px;
  height: auto;
  margin:0 0 10px 0;
  padding-right:0px;

  @media screen and (max-width:990px){
    margin-top:50px;
    justify-self:center;
  }

`;


export const ApproachDetails = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  width: 100%;
  height:100%;
`;

export const ApproachDetailTitle = styled.h3`
  color:#951f76;
  margin-bottom:40px;
  font-size:1.5rem;

  @media screen and (max-width:990px){
    text-align:center;
    margin-bottom:25px;
    margin-top:25px;
    font-size:18px;
  }

  
`;

export const ApproachSolutionWrapper = styled.div`
  display:grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items:center;
  grid-template-areas: 'col1 col2';
  width: 100%;

  @media screen and (max-width: 990px){
    display:unset; 
  }

`;

export const SolutionColumn1 = styled.div`
  margin-bottom:15px;
  padding:0 15px;
  grid-area: col1; 
  
  @media screen and (max-width: 990px){
    margin-bottom:0px;
  }
  
`;

export const SolutionColumn2 = styled.div`
  margin-bottom:15px;
  padding:0 15px;
  grid-area: col2;  

  @media screen and (max-width: 990px){
    margin-bottom:0px;
    justify-content:center;
  }

`;

export const SolutionItemWrapper = styled.ul`
  list-style:none;
`;

export const SolutionItem = styled.li`
    display:flex;
    align-items:center;
    min-height:40px;
    font-size:1.2rem;
    line-height:24px;
    padding-left:30px;
    :before{
      content:"";
      width:15px;
      height:15px;
      border-radius:50%;
      background:#951f76;
      margin-left:-30px;
      position:absolute;
      
    }

    @media screen and (max-width: 990px){
      font-size:16px;

      :before{
        content:"";
        width:10px;
        height:10px;
        border-radius:50%;
        background:#951f76;
        margin-left:-30px;
        position:absolute;
        
      }


    }

`;

export const WhatWeOfferWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  @media screen and (min-width: 1280px) and (max-width:1500px){
    padding:20px;
  }  


  @media screen and (min-width: 991px) and (max-width:1279px){
    padding:20px;
  }  

  @media screen and (max-width: 990px){
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }  

`;

export const WhatWeOfferItemWrappper = styled.div`
  align-items: center;
  display:flex;
  flex-direction:column;
  /* background: #fff;
  box-shadow: 10px 10px 18px 0 rgb(96 96 96 / 12%); */


  z-index: 2;
`;

export const ItemIcon = styled.img`
  width:auto;
  margin-top:20px;
  margin-bottom:20px;
  max-height:40px;

`;

export const ItemTitle = styled.h3`
    font-size: 18px;
    line-height: 29px;
    font-weight: 700;
    color: #605559;
    margin-bottom: 15px;  
    text-align:left;
`;

export const ItemDescription = styled.p`
  font-size:1.1rem;
  text-align:justify;
  line-height:24px;
`;


export const SolutionItemPreWrapper = styled.ul`
  list-style:none;
`;

export const SolutionPreItem = styled.li`
    display:flex;
    position: relative;
    align-items:self-start;
    min-height:40px;
    font-size:18px;
    line-height:24px;
    padding-left:30px;
    

    :before{
      content:"";
      width:15px;
      height:15px;
      border-radius:50%;
      background:#951f76;
      margin-left:-30px;
      position:absolute;
      top:5px;
    }

    :last-child{
      margin-bottom:15px;
    }

    span{
      display:contents;
      color:#d14e3a;
    }

    @media screen and (max-width:990px){
      font-size:16px;

      :before{
        content:"";
        width:10px;
        height:10px;
        border-radius:50%;
        background:#951f76;
        margin-left:-30px;
        position:absolute;
      }

      

      span{
        display:contents;
        background:red;
      }



    }    
`;

export const MoreInfoWrapper = styled.div`
  display: flex;
  justify-content:center;
  margin-top:40px;
  
  button{
    max-width:150px;
  }

`;

