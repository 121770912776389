import React from 'react'
import {
  SideBarContainer,
  Icon,
  CloseIcon,
  SideBarMenu,
  SideBarLink,
  SideBarRoute
} from './sidebar.style.js';

const goToBlog = () => {
  window.location = 'https://blog.nodify.tech';
}


function SideBar({isOpen,toggle}) {
  return (
    <SideBarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SideBarMenu>
        <SideBarLink to="about" onClick={toggle}>About</SideBarLink>
        <SideBarLink to="team" onClick={toggle}>Team</SideBarLink>
        <SideBarLink to="approach" onClick={toggle}>Approach</SideBarLink>
        <SideBarLink to="whatweoffer" onClick={toggle}>What We Offer</SideBarLink>
        <SideBarLink to="contact">Contact</SideBarLink>
        <SideBarLink onClick={goToBlog}>Blog</SideBarLink>
        <SideBarRoute to="become-a-partner">Become a Partner</SideBarRoute>
      </SideBarMenu>



    </SideBarContainer>
  )
}

export default SideBar;