export const alper = {
  name: 'Alper TOR',
  title:'Managing Partner, Head of Development',
  img: '/assets/images/alper-tor.jpg',
  summary:"He is a passionate, long time coder, entrepreneur and architect. He cofounded his first company in 1997, and released his first software product, Spinnaker which was a data forms design and management platform, in 2000.<br/><br/>Alper's focus shifted towards document lifecycle management technologies, hardware, software and their integration, between 2001 and 2008. His team created 3 product families sold internationally, and delivered many projects technologically challenging directly and indirectly, nationally and internationally.<br/><br/>He founded DocPlant in 2009 and concentrated only on software side of DLM technologies and related product development. Flows has been a new area of development since 2019 due to customer demand.DocPlant prioritized international partners mostly in Australia, New Zealland, the UK and South Africa.<br/><br/>He works relentlessly on identifying and understanding the needs and the reasons, and focuses on designing, developing and delivering the answers in the form of code, ideas and perfomances as a team and as an individual.",
  linkedin:'https://www.linkedin.com/in/alper-t%C3%B6r-59723611/'
}

export const duygu = {
  name: 'Ayse Duygu MIZRAK',
  title:'Head of Analysis and Implementation',
  img: '/assets/images/duygu-mizrak.jpg',
  summary:'With 10+ years of experience, Duygu brings expertise of digitizing business processes. The pojects at several departments of companies from many different industries in which she played a major role,  made her able to see the vital connections between business layers. She has significant self-confidence about digitizing any process no matter how complicated it is. Study of Industrial Engineering helped her to develop analytical thinking skills which is an huge advantage for process modelling.',
  linkedin:'https://www.linkedin.com/in/duygumizrak/'
}

export const clark = {
  name: 'Clark Coetzee',
  title:'Channel Partner Executive',
  img: '/assets/images/clark-coetzee.jpg',
  summary:'As an experienced Relationship Builder over a period of more than 30 years, the key has always been trust, honesty and integrity with all Stakeholders. Clark’s passion to build a win-win Partner Channel that delivers meaningful and value to customers by implementing well-designed digital solutions. <br/><br/>With grass roots in the motor industry, he established and implemented several Project Offices that managed the vehicle component development and testing processes through to the final new or upgrades model launches. Using the skills learnt through Programme Management courses by the acclaimed Dr Harold Kerzner and Goldratt Institute’s Jonah programs, he managed many projects successfully. <br/><br/>However, with the growth in information technology, his passion moved to automating processes and designing digital solutions of value by using Tools such as those offered by Nodify Technologies.',
  linkedin:'https://www.linkedin.com/in/clark-coetzee-610b288/'
}

export const berat = {
  name: 'Berat TOR',
  title:'Development Leader',
  img: '/assets/images/berat-tor.jpg',
  summary:"Berat is a senior software developer who has been creating websites since the late 90's. With the combination of frontend,backend skills and the passion of web development he has built countless web apps in different countries and sectors. In the last years he has specialized on dms systems and workflows.",
  linkedin:'https://www.linkedin.com/in/berat-tor/'
}

export const ertug = {
  name: 'Ertug ER',
  title:'Managing Partner',
  img: '/assets/images/ertug-er.jpg',
  summary:'Starting the business life adventure in 1999, Ertug had spent several years as enterprise software systems implementation consultant, successfully completed number of projects in different range of industries. <br/><br/>He then led setting up management systems of different telecom operators and service providers, implementing  processes related to the rollout of technology services in large scale projects, satisfying number of various business requirements set by many different business units.<br/><br/>After he founded EuTek Business Solutions in 2008, he continued his career managing various projects, people, customers, relations and perceptions. Focused on telecommunications and utilities industries, EuTek has been helping industry leading clients to automate their processes and make these processes more visible and efficient.<br/><br/> Ertug believes trust and empathy are the key factors of success',
  linkedin:'https://www.linkedin.com/in/ertug-er-4899a1b/'
}

export const serdar = {
  name: 'Serdar INAN',
  title:'Head of Sales/Marketing',
  img: '/assets/images/serdar-inan.jpg',
  summary:"With a rich legacy spanning over a decade in the dynamic field of technology sales and business development, Serdar is a results-driven professional committed to driving business growth through strategic lead generation and cultivating lasting relationships. Serdar's expertise spans a wide range of technology domains, enabling him to effectively bridge the gap between innovative solutions and customer needs.",
  linkedin:'https://www.linkedin.com/in/serdarinan/'
}

