import React from 'react';
import { LoadingLayerWrapper,LoadingIndicator } from './loadinglayer.style';

const LoadingLayer = ({show}) => {
  return (
    <>
    <LoadingLayerWrapper show={show}>
      <LoadingIndicator />
    </LoadingLayerWrapper>
    </>
  )
}

export default LoadingLayer;