import React from 'react';
import purify from "dompurify";
import { FaLinkedin } from 'react-icons/fa';
import {
  DetailTeamPopup,
  DetailImg,
  DetailGrid,
  CloseButton,
  DetailLeft,
  DetailName,
  DetailTitle,
  Linkedin,
  DetailRight,
  TeamDetailWrapper,
  DetailGridWrapper,
  ShortBio
} from './teamdetail.style';
const TeamDetail = ({people,stateChanger,visible}) => {
  //src={people.img}
  function validateURL(url) {
    const parsed = new URL(url)
    return ['https:', 'http:'].includes(parsed.protocol)
  }  
  return (
    <>
    {
      people !== null ?
      (
      <DetailTeamPopup visible={visible} >
          <TeamDetailWrapper>
            <CloseButton onClick={()=>stateChanger(false)}><img src={require('../../assets/images/close-button.png')} alt="" /></CloseButton>
            <DetailGridWrapper>
              <DetailGrid>
                <DetailLeft>
                  <DetailImg src={people.img} alt={people.name} />
                  <DetailName>{people.name}</DetailName>
                  <DetailTitle>{people.title}</DetailTitle>
                  <Linkedin href={validateURL(people.linkedin) ? people.linkedin : ''} target="_blank" aria-label="Linkedin">
                    <FaLinkedin />
                  </Linkedin>                                                                    
                </DetailLeft>
                <DetailRight>
                    <ShortBio dangerouslySetInnerHTML={{ __html: purify.sanitize(people.summary) }} />
                </DetailRight>
              </DetailGrid>
            </DetailGridWrapper>
          </TeamDetailWrapper>
      </DetailTeamPopup>
      ):""
    }
    </>
  )
}

export default TeamDetail;